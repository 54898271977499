import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import PASSEIOS from "../../assets/img/passeios.jpg";
import {
  ClickHereStyle,
  GoBackButton,
  ImageRelationshipCard,
  RelationshipCircularIcon,
  RelationshipDetailsCard,
  RelationshipDetailsCardContent,
  RelationshipDetailsPageCardTitle,
  RelationshipDetailsPageStyle,
  RelationshipDetailsPageTitle,
  RelationshipDetailsSubText2,
  SecondColumnDetailsPage,
  StyledArrowIcon,
  WrapBackButton,
  WrapContent,
  WrapContent2,
} from "../../styles/Relationship";
import { TbPuzzleFilled } from "react-icons/tb";
import { endpoint } from "../../utils/Constants";
import { getMemories } from "../../data/services/memories_service";
import MemoryModel from "../../data/model/memory";


export default function MemoryClub() {
  const [expandedCardId, setExpandedCardId] = useState<number | null>(null); 
  const [memories, setMemories] = useState<MemoryModel[]>([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const handleCardClick = (id: number) => {
    setExpandedCardId(expandedCardId === id ? null : id);
  };

  const handleBackButton = () => {
    navigate("/");
  };

  useEffect(() => {
    async function fetchTripsData() {
      try {
        const { events } = await getMemories();
        setMemories(events);
      } catch (error) {
        console.error("Erro ao buscar as viagens:", error);
      } finally {
        setLoading(false);
      }
    }
    fetchTripsData();
  }, []);

  return (
    <>
      <RelationshipDetailsPageStyle>
        <WrapContent>
          <WrapBackButton onClick={handleBackButton}>
            <StyledArrowIcon size={23} />
            <GoBackButton>Voltar para página inicial</GoBackButton>
          </WrapBackButton>
        </WrapContent>
        <WrapContent2>
          <RelationshipCircularIcon>
            <TbPuzzleFilled size={50} color="#1270B0" />
          </RelationshipCircularIcon>
          <RelationshipDetailsPageTitle>
            Clube da Memória
          </RelationshipDetailsPageTitle>
          {loading ? (
            <p>Carregando...</p>
          ) : (
            memories.map((memory) => (
              <RelationshipDetailsCard
                key={memory.id}
                onClick={() => handleCardClick(memory.id!)}
                expanded={expandedCardId === memory.id}
              >
                <ImageRelationshipCard
                  src={`${endpoint}${memory.fotoSmallUrl}`}
                  expanded={expandedCardId === memory.id}
                />
                <SecondColumnDetailsPage>
                  <RelationshipDetailsPageCardTitle
                    expanded={expandedCardId === memory.id}
                  >
                    {memory.titulo}
                  </RelationshipDetailsPageCardTitle>

                  
                  {!expandedCardId || expandedCardId !== memory.id ? (
                    <p>{memory.sub_titulo}</p>
                  ) : null}

                  
                  {expandedCardId === memory.id && (
                    <RelationshipDetailsSubText2
                      dangerouslySetInnerHTML={{ __html: memory.descricao! }}
                    />
                  )}

                  <ClickHereStyle expanded={expandedCardId === memory.id}>
                    Clique e veja todos os detalhes
                  </ClickHereStyle>
                  <RelationshipDetailsCardContent
                    expanded={expandedCardId === memory.id}
                  >
                  </RelationshipDetailsCardContent>
                </SecondColumnDetailsPage>
              </RelationshipDetailsCard>
            ))
          )}
        </WrapContent2>
      </RelationshipDetailsPageStyle>
    </>
  );
}
