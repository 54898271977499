export default class ActivitiesListModel {
    id: number | null;
    titulo_Parceiro: string | null;
    sub_Titulo_Parceiro: string | null;
    descricao_Parceiro: string | null;
    imagem_Parceiro_Url: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    publishedAt: string | null;
    titulo_card: string | null;
    sub_titulo_card: string | null;

    constructor(
        id: number | null,
        titulo_Parceiro: string | null,
        sub_Titulo_Parceiro: string | null,
        descricao_Parceiro: string | null,
        imagem_Parceiro_Url: string | null,
        createdAt: string | null,
        updatedAt: string | null,
        publishedAt: string | null,
        titulo_card: string | null,
        sub_titulo_card: string | null,
    ) {
        this.id = id;
        this.titulo_Parceiro = titulo_Parceiro;
        this.sub_Titulo_Parceiro = sub_Titulo_Parceiro;
        this.descricao_Parceiro = descricao_Parceiro;
        this.imagem_Parceiro_Url = imagem_Parceiro_Url;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
        this.publishedAt = publishedAt;
        this.titulo_card = titulo_card;
        this.sub_titulo_card = sub_titulo_card;
    }

    static fromApiResponse(data: any): ActivitiesListModel {
        return new ActivitiesListModel(
            data.id || null,
            data.attributes.titulo_parceiro || null,
            data.attributes.sub_titulo_parceiro || null,
            data.attributes.descricao_parceiro || null,
            data.attributes.imagem_parceiro?.data?.attributes?.url || null,
            data.attributes.createdAt || null,
            data.attributes.updatedAt || null,
            data.attributes.publishedAt || null,
            data.attributes.titulo_card || null,
            data.attributes.sub_titulo_card || null,
        );
    }
}
