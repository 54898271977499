import Header from "../../components/Header/Header";
import {
  AbaceText,
  AbaceTitle,
  Ellipse,
  EllipseText,
  FirstRowAbace,
  HorizontalDivision,
  ImageFirstRow,
  WrapContentFirstRow,
  WrapTextsFirstRow,
  VideoContainer, // Adicionando o novo componente para envolver o vídeo e os containers
  ContainerBehindVideo,
  ContainerBehindVideo2,
} from "../../styles/About";
import ABACEABOUT_VIDEO from "../../assets/video/abaceVideo.mp4";
import HistoryAbout from "../../components/HistoryAbout/HistoryAbout";
import Footer from "../../components/Footer/Footer";

export default function About() {
  return (
    <>
      <Header />
      <FirstRowAbace>
        <WrapContentFirstRow>
          <VideoContainer>
            <ContainerBehindVideo />
            <ContainerBehindVideo2 />
            <ImageFirstRow autoPlay loop muted>
              <source src={ABACEABOUT_VIDEO} type="video/mp4" />
              Your browser does not support the video tag.
            </ImageFirstRow>
          </VideoContainer>
          <WrapTextsFirstRow>
            <AbaceTitle>A ABACE</AbaceTitle>
            <AbaceText responsive>
              A ABACE foi criada no calor das lutas em defesa da paridade de
              remuneração entre funcionários da ativa e aposentados. Nasceu da
              ideia de um reduzido grupo de aposentados de criar uma entidade
              que os representasse na defesa de seus direitos e, no mesmo passo,
              oferecesse aos seus associados um local de encontro para
              proporcionar a eles a oportunidade de convívio e desenvolvimento
              de outras atividades de congraçamento. Daí vieram os almoços, o
              exercício de aptidões intelectuais com o uso do jornal da ABACE,
              enfim, nasceu o companheirismo pós vida de funcionário do Banco. E
              essa tem sido a razão de sua existência nos seus quase 40 anos de
              existência, ela que foi fundada em junho de 1987.
            </AbaceText>
          </WrapTextsFirstRow>
        </WrapContentFirstRow>
      </FirstRowAbace>
      <HorizontalDivision>
        <Ellipse>
          <EllipseText>Nossa história</EllipseText>
        </Ellipse>
      </HorizontalDivision>
      <HistoryAbout />
      <Footer />
    </>
  );
}
