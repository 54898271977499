import AlbumModel, { FormatDetailModel, FormatsModel, FotoModel } from "../model/album";
import { fetchAlbums } from "../provider/api";

export let yearSelected: string;
export let albumSelected: string | null = null;
export let nameSelected: string | null = null;

export const setYearSelectedService = (year: string) => {
  yearSelected = year;
};

export const setNameService = (name: string) => {
  nameSelected = name;
};

export const setAlbumSelectedService = (album: string) => {
  albumSelected = album;
};

export const getYearSelectedService = () => {
  return yearSelected;
};

export const getAlbumSelectedService = () => {
  return albumSelected;
};

export async function getAlbums(): Promise<{ albums: AlbumModel[] | null }> {
  try {
      const response = await fetchAlbums(); 
      

      const albumsData = response.data;

      
      const albums = albumsData.map((albumData: any) => {
          const attributes = albumData.attributes;
          const fotos = attributes.fotos?.data?.map((fotoData: any) => {
              const fotoAttributes = fotoData.attributes;
              const formats = fotoAttributes.formats;

              return new FotoModel(
                  fotoData.id,
                  fotoAttributes.name,
                  fotoAttributes.alternativeText,
                  fotoAttributes.caption,
                  fotoAttributes.width,
                  fotoAttributes.height,
                  new FormatsModel(
                      formats?.thumbnail ? new FormatDetailModel(
                          formats.thumbnail.name,
                          formats.thumbnail.hash,
                          formats.thumbnail.ext,
                          formats.thumbnail.mime,
                          formats.thumbnail.path,
                          formats.thumbnail.width,
                          formats.thumbnail.height,
                          formats.thumbnail.size,
                          formats.thumbnail.sizeInBytes,
                          formats.thumbnail.url
                      ) : null,
                      formats?.small ? new FormatDetailModel(
                          formats.small.name,
                          formats.small.hash,
                          formats.small.ext,
                          formats.small.mime,
                          formats.small.path,
                          formats.small.width,
                          formats.small.height,
                          formats.small.size,
                          formats.small.sizeInBytes,
                          formats.small.url
                      ) : null,
                      formats?.medium ? new FormatDetailModel(
                          formats.medium.name,
                          formats.medium.hash,
                          formats.medium.ext,
                          formats.medium.mime,
                          formats.medium.path,
                          formats.medium.width,
                          formats.medium.height,
                          formats.medium.size,
                          formats.medium.sizeInBytes,
                          formats.medium.url
                      ) : null,
                      formats?.large ? new FormatDetailModel(
                          formats.large.name,
                          formats.large.hash,
                          formats.large.ext,
                          formats.large.mime,
                          formats.large.path,
                          formats.large.width,
                          formats.large.height,
                          formats.large.size,
                          formats.large.sizeInBytes,
                          formats.large.url
                      ) : null
                  ),
                  fotoAttributes.hash,
                  fotoAttributes.ext,
                  fotoAttributes.mime,
                  fotoAttributes.size,
                  fotoAttributes.url,
                  fotoAttributes.previewUrl,
                  fotoAttributes.provider,
                  fotoAttributes.provider_metadata,
                  fotoAttributes.createdAt,
                  fotoAttributes.updatedAt
              );
          }) || [];

          return new AlbumModel(
              albumData.id,
              attributes.ano,
              attributes.createdAt,
              attributes.updatedAt,
              attributes.publishedAt,
              attributes.nome_album,
              fotos
          );
      });

      return { albums };

  } catch (error: any) {
      console.error(`Erro (${error.response?.status}) ao buscar álbuns`);
      console.log(error.toString());
      return { albums: null };
  }
}
