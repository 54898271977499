import React, { useState, useEffect } from "react";
import { IoIosArrowDropleft, IoIosArrowDropright } from "react-icons/io";
import { getAlbums } from "../../data/services/Gallery_service";

interface YearCarouselProps {
  selectedYear: string;
  setSelectedYear: React.Dispatch<React.SetStateAction<string>>;
}

const YearCarousel: React.FC<YearCarouselProps> = ({
  selectedYear,
  setSelectedYear,
}) => {
  const [years, setYears] = useState<number[]>([]);
  const [transitioning, setTransitioning] = useState(false);
  const [direction, setDirection] = useState<"left" | "right">("right");
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchAlbums = async () => {
      try {
        const response = await getAlbums();
        
        const albumsYears = Array.from(
          new Set(
            response.albums!
              .map((album) => album.ano) 
              .filter((year) => year !== null && !isNaN(Number(year))) 
              .map((year) => Number(year)) 
          )
        ).sort((a, b) => b - a); 

        setYears(albumsYears); 
        if (albumsYears.length > 0) {
          setSelectedYear(albumsYears[0].toString()); 
        }
      } catch (error) {
        console.error("Erro ao buscar anos:", error);
        setError("Erro ao carregar anos.");
      }
    };

    fetchAlbums();
  }, [setSelectedYear]);

  const handlePrevYear = () => {
    if (!transitioning && years.length > 0) {
      setDirection("left");
      setTransitioning(true);
      setTimeout(() => {
        const currentIndex = years.indexOf(parseInt(selectedYear));
        const newIndex = (currentIndex - 1 + years.length) % years.length;
        setSelectedYear(years[newIndex].toString());
        setTransitioning(false);
      }, 300);
    }
  };

  const handleNextYear = () => {
    if (!transitioning && years.length > 0) {
      setDirection("right");
      setTransitioning(true);
      setTimeout(() => {
        const currentIndex = years.indexOf(parseInt(selectedYear));
        const newIndex = (currentIndex + 1) % years.length;
        setSelectedYear(years[newIndex].toString());
        setTransitioning(false);
      }, 300);
    }
  };

  const getDisplayYears = () => {
    if (years.length === 0) return [];
    const currentIndex = years.indexOf(parseInt(selectedYear));
    const prevIndex = (currentIndex - 1 + years.length) % years.length;
    const nextIndex = (currentIndex + 1) % years.length;

    return [years[prevIndex], years[currentIndex], years[nextIndex]];
  };

  const displayYears = getDisplayYears();

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "300px",
      }}
    >
      <IoIosArrowDropleft
        size={38}
        color="#414C80"
        onClick={handlePrevYear}
        style={{ cursor: "pointer" }}
      />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "80%",
          overflow: "hidden",
        }}
      >
        {displayYears.map((year, index) => {
          const opacity = year === parseInt(selectedYear) ? 1 : 0.5;
          const fontSize = year === parseInt(selectedYear) ? "2em" : "1.5em";
          const margin = year === parseInt(selectedYear) ? "0 10px" : "0 10px";

          return (
            <div
              key={index}
              style={{
                opacity,
                fontSize,
                margin,
                transition: `transform 0.3s ease, opacity 0.3s ease`,
                transform: transitioning
                  ? direction === "left"
                    ? "translateX(-100%)"
                    : "translateX(100%)"
                  : "translateX(0)",
              }}
            >
              {year}
            </div>
          );
        })}
      </div>
      <IoIosArrowDropright
        size={38}
        color="#414C80"
        onClick={handleNextYear}
        style={{ cursor: "pointer" }}
      />
    </div>
  );
};

export default YearCarousel;
