import Calendario from "../../components/Calendar/Calendar";
import {
  Ellipse,
  EllipseSecondText,
  EllipseText,
  EventPageSub,
  EventPageTitle,
  EventStyle,
  HorizontalDivision,
} from "../../styles/Events";

export default function Events() {
  return (
    <>
      <HorizontalDivision />
      <EventStyle>
        <EventPageTitle>CALENDÁRIO DE ATIVIDADES</EventPageTitle>
        <EventPageSub>
          Clique em cima da data e veja se naquele dia existem eventos agendados.
        </EventPageSub>
        <Calendario />
      </EventStyle>
      <HorizontalDivision>
        <Ellipse>
          <EllipseText>ESTAMOS AQUI PARA</EllipseText>
          <EllipseSecondText>CRIAR LAÇOS</EllipseSecondText>
        </Ellipse>
      </HorizontalDivision>
    </>
  );
}
