export default class PartnerModel {
    id: number | null;
    url_parceiro: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    publishedAt: string | null;
    icone_parceiro: IconeParceiroModel | null;
  
    constructor(
      id: number | null,
      url_parceiro: string | null,
      createdAt: string | null,
      updatedAt: string | null,
      publishedAt: string | null,
      icone_parceiro: IconeParceiroModel | null
    ) {
      this.id = id;
      this.url_parceiro = url_parceiro;
      this.createdAt = createdAt;
      this.updatedAt = updatedAt;
      this.publishedAt = publishedAt;
      this.icone_parceiro = icone_parceiro;
    }
  }
  
  export class IconeParceiroModel {
    id: number | null;
    name: string | null;
    alternativeText: string | null;
    caption: string | null;
    width: number | null;
    height: number | null;
    formats: ImageFormatsModel | null;
    hash: string | null;
    ext: string | null;
    mime: string | null;
    size: number | null;
    url: string | null;
    previewUrl: string | null;
    provider: string | null;
    provider_metadata: any | null;
    createdAt: string | null;
    updatedAt: string | null;
  
    constructor(
      id: number | null,
      name: string | null,
      alternativeText: string | null,
      caption: string | null,
      width: number | null,
      height: number | null,
      formats: ImageFormatsModel | null,
      hash: string | null,
      ext: string | null,
      mime: string | null,
      size: number | null,
      url: string | null,
      previewUrl: string | null,
      provider: string | null,
      provider_metadata: any | null,
      createdAt: string | null,
      updatedAt: string | null
    ) {
      this.id = id;
      this.name = name;
      this.alternativeText = alternativeText;
      this.caption = caption;
      this.width = width;
      this.height = height;
      this.formats = formats;
      this.hash = hash;
      this.ext = ext;
      this.mime = mime;
      this.size = size;
      this.url = url;
      this.previewUrl = previewUrl;
      this.provider = provider;
      this.provider_metadata = provider_metadata;
      this.createdAt = createdAt;
      this.updatedAt = updatedAt;
    }
  }
  
  class ImageFormatsModel {
    thumbnail: ImageFormatDetailsModel | null;
    large: ImageFormatDetailsModel | null;
    medium: ImageFormatDetailsModel | null;
    small: ImageFormatDetailsModel | null;
  
    constructor(
      thumbnail: ImageFormatDetailsModel | null,
      large: ImageFormatDetailsModel | null,
      medium: ImageFormatDetailsModel | null,
      small: ImageFormatDetailsModel | null
    ) {
      this.thumbnail = thumbnail;
      this.large = large;
      this.medium = medium;
      this.small = small;
    }
  }
  
  class ImageFormatDetailsModel {
    name: string | null;
    hash: string | null;
    ext: string | null;
    mime: string | null;
    path: string | null;
    width: number | null;
    height: number | null;
    size: number | null;
    url: string | null;
  
    constructor(
      name: string | null,
      hash: string | null,
      ext: string | null,
      mime: string | null,
      path: string | null,
      width: number | null,
      height: number | null,
      size: number | null,
      url: string | null
    ) {
      this.name = name;
      this.hash = hash;
      this.ext = ext;
      this.mime = mime;
      this.path = path;
      this.width = width;
      this.height = height;
      this.size = size;
      this.url = url;
    }
  }
  