import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header/Header";
import YearCarousel from "../../components/YearCarousel/YearCarousel";
import {
  ColumnMediaTitle,
  GalleryPageTitle,
  GalleryStyle,
  GallerySubText,
  GallerySubText2,
  GridContainer,
  HorizontalDivision,
  MediaTitle,
  RectangleDivisor,
  WrapContent,
  WrapMedia,
} from "../../styles/Gallery";
import { GALLERYITEMS } from "../../core/app-urls";
import {
  getAlbums,
  setAlbumSelectedService,
  setNameService,
  setYearSelectedService,
} from "../../data/services/Gallery_service";
import { endpoint } from "../../utils/Constants";

const GalleryPage: React.FC = () => {
  const navigate = useNavigate();
  const [selectedYear, setSelectedYear] = useState<string>("2024");
  const [albums, setAlbums] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchAlbums = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await getAlbums();
        const filteredAlbums = response.albums!.filter(
          (album: any) => album.ano === selectedYear
        );
        setAlbums(filteredAlbums);
      } catch (error) {
        console.error("Erro ao buscar álbuns:", error);
        setError("Erro ao carregar álbuns.");
      } finally {
        setLoading(false);
      }
    };

    fetchAlbums();
  }, [selectedYear]);

  const handleClickPhotos = (albumId: any, name: string) => {
    setYearSelectedService(selectedYear);
    setAlbumSelectedService(albumId);
    setNameService(name);
    navigate(`${GALLERYITEMS}/${albumId}`);
  };

  return (
    <>
      <Header />
      <GalleryStyle>
        <WrapContent>
          <GalleryPageTitle>Galeria</GalleryPageTitle>
          <GallerySubText>
            Acesse álbuns de fotos de variados acontecimentos da associação.
          </GallerySubText>
          <GallerySubText2>
            CLIQUE NAS SETAS E ESCOLHA O ANO QUE DESEJA VER AS IMAGENS E VÍDEOS
            DOS EVENTOS.
          </GallerySubText2>
          <HorizontalDivision>
            <RectangleDivisor>
              <YearCarousel
                selectedYear={selectedYear}
                setSelectedYear={setSelectedYear}
              />
            </RectangleDivisor>
          </HorizontalDivision>
        </WrapContent>
        <WrapMedia>
          {loading ? (
            <h3 style={{ textAlign: "center" }}>Carregando...</h3>
          ) : error ? (
            <h3 style={{ textAlign: "center" }}>{error}</h3>
          ) : albums.length > 0 ? (
            <GridContainer>
              {albums.map((album) => (
                <ColumnMediaTitle key={album.id}>
                  <div
                    className="gallery"
                    onClick={() =>
                      handleClickPhotos(album.id, album.nome_album)
                    }
                  >
                    <div
                      className="upside-down card"
                      style={{ backgroundColor: "#B2E5E2" }}
                    ></div>
                    <div
                      className="diagonal card"
                      style={{ backgroundColor: "#E6F0E6" }}
                    ></div>
                    <div
                      className="normal card"
                      style={{ backgroundColor: "#ff0000" }}
                    ></div>
                    <div
                      className="vertical card"
                      style={{ backgroundColor: "#D5CDCD" }}
                    ></div>
                    <div
                      className="rotated card"
                      style={{ backgroundColor: "#F0E6F0" }}
                    ></div>
                    {album.fotos && album.fotos.length > 0 && (
                      <img
                        src={`${endpoint}${album.fotos[0].url}`}
                        alt={album.nome_album}
                        className="normal"
                      />
                    )}
                  </div>
                  <MediaTitle>{album.nome_album}</MediaTitle>
                </ColumnMediaTitle>
              ))}
            </GridContainer>
          ) : (
            <h3 style={{ textAlign: "center" }}>
              Não há registros deste ano até o momento.
            </h3>
          )}
        </WrapMedia>
      </GalleryStyle>
    </>
  );
};

export default GalleryPage;
