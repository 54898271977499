import { useParams, useNavigate } from "react-router-dom";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";

import BookClub from "./BookClub";
import Trips from "./Trips";
import MemoryClub from "./MemoryClub";
import {
  WrapContent,
  WrapContent2,
} from "../../styles/Relationship";
import { useEffect } from "react";

export default function RelationshipDetailsPage() {
  const { id } = useParams();
  const navigate = useNavigate();

  const handleBackButton = () => {
    navigate("/");
  };

  const renderActivityComponent = () => {
    switch (id) {
      case "2":
        return <BookClub />;
      case "3":
        return <Trips />;
      case "5":
        return <MemoryClub />;
      default:
        return null; 
    }
  };

  useEffect(() => {
    if (!["2", "3", "5"].includes(id!)) {
      navigate("/eventos"); 
    }
  }, [id, navigate]);

  return (
    <>
      <Header />
      <WrapContent>
        <WrapContent2>
          {/* <WrapBackButton onClick={handleBackButton}>
            <StyledArrowIcon size={23} />
            <GoBackButton>Voltar para página inicial</GoBackButton>
          </WrapBackButton> */}
            {renderActivityComponent()}
        </WrapContent2>
      </WrapContent>
      <Footer />
    </>
  );
}
