import styled from "styled-components";
import { IoArrowBackCircleOutline } from "react-icons/io5";

export const ActivitiesDetailsPageStyle = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

export const WrapContent = styled.div`
    display: flex;
    align-self: center;
    width: 75%;

    @media screen and (max-width: 1024px) {
      margin-bottom: 40px;
    }
`

export const WrapContent2 = styled.div`
    display: flex;
    align-self: center;
    justify-content: center;
    width: 75%;
    flex-direction: column;

    @media screen and (max-width: 1024px) {
      width: 90%;
    }
`

export const WrapBackButton = styled.button`
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    background-color: transparent;
    border: none;
    gap: 15px
`

export const GoBackButton = styled.h1`
    font-family: "Cinzel", sans-serif;
    font-size: 15pt;
    font-weight: 400;
    align-self: self-start;
    color: black;
    background-color: transparent;
    border: none;
    margin-top: 55px;
    cursor: pointer;
`

export const StyledArrowIcon = styled(IoArrowBackCircleOutline)`
  color: #000; 
  margin-top: 45px;
`

export const ActivitiesCircularIcon = styled.div`
    background-color: white;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    align-self: center;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
    justify-content: center;
    align-items: center;
    /* display: flex; */
    display: none;
`

export const ActivityDetailsPageTitle = styled.h1`
  font-family: "Baloo Bhai 2", sans-serif;
  font-size: 35pt;
  font-weight: 500;
  color: #414c80;
  align-self: center;
  text-align: center;
  margin-bottom: 0;
  margin-top: 0;

  @media screen and (max-width: 1024px) {
    font-size: 20pt;
  }
`;

export const ActivityDetailsSubText = styled.h1`
  font-family: "Actor", sans-serif;
  font-size: 13pt;
  font-weight: 400;
  width: 500px;
  color: black;
  align-self: center;
  text-align: center;
  margin-top: 0; 
  
  @media screen and (max-width: 1024px) {
    width: 100%;
  }
`;

interface ActivitiesCardProps {
  expanded?: boolean;
}

export const ActivitiesDetailsCard = styled.div<ActivitiesCardProps>`
    width: 100%;
    height: ${props => props.expanded ? 'auto' : 'auto'};
    transition: height 1.2s ease;
    margin-top: 40px;
    margin-bottom: 40px;
    background-color: #F1F1F1;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
    cursor: pointer;
    display: flex;
    flex-direction: ${props => props.expanded ? 'column' : 'row'};

    @media screen and (max-width: 1024px) {
      justify-content: center;
      align-items: center;
    }
`

export const ImageActivitiesCard = styled.img<ActivitiesCardProps>`
    height: auto;
    width: 300px; 
    border-radius: 8px;
    object-fit: cover;
    align-self: center;
    margin-left: ${props => props.expanded ? '0' : '20px'};
    margin-top: ${props => props.expanded ? '15px' : '0'};

    @media screen and (max-width: 1024px) {
        height: 80px;
        width: 200px ;
        padding-left: 0;
    }
`

export const ActivityDetailsPageCardTitle = styled.h1<ActivitiesCardProps>`
  font-family: "Baloo Bhai 2", sans-serif;
  font-size: 20pt;
  font-weight: 500;
  color: #414c80;
  align-self: ${props => props.expanded ? 'center' : 'self-start'};
  text-align: center;
  margin-bottom: 0;
  margin-top: 0;
  margin-left: 15px;

  @media screen and (max-width: 1024px) {
    font-size: 15pt;
  }
`

export const SecondColumnDetailsPage = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 12px;

    @media screen and (max-width: 1024px) {
      margin: 2px;
    }
`

export const ActivityDetailsSubText2 = styled.h1<ActivitiesCardProps>`
  font-family: "Actor", sans-serif;
  font-size: 13pt;
  font-weight: 400;
  color: black;
  align-self: ${props => props.expanded ? 'center' : 'self-start'};
  margin-top: 0;
  margin-left: 15px;

  @media screen and (max-width: 1024px) {
    display: none;
  }
`

export const ActivityDetailsCardContent = styled.h1<ActivitiesCardProps>`
  font-family: "Actor", sans-serif;
  font-size: 13pt;
  font-weight: 400;
  color: black;
  display: ${props => props.expanded ? 'block' : 'none'};
  align-self: center;
  text-align: center;
  margin-top: 15px;
  width: 95%;
`

export const ClickHereStyle = styled.h1<ActivitiesCardProps>`
  display: ${props => props.expanded ? 'none' : 'block'};
  font-family: "Baloo Bhai 2", sans-serif;
  font-size: 12pt;
  font-weight: 600;
  color: blue;
  margin-left: 15px;
  margin-top: 45px;
  opacity: .7;
`