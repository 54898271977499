import React, { useState, useEffect, useRef } from "react";
import {
  AbaceText,
  AppButton,
  ButtonIcon,
  ButtonIconRight,
  CellphoneImage,
  Dot,
  PaginationDots,
  SliderContainer,
  SliderMediaIconsRow,
  SubtitleText,
  TitleColumn,
  WelcomeText,
  WrapButtonAndPagination,
} from "../../styles/Slider";
import { IoIosArrowDropleft, IoIosArrowDropright } from "react-icons/io";
import INSTAGRAMLOGO from "../../assets/img/instagramLogo.png";
import FACEBOOKLOGO from "../../assets/img/facebookLogo.png";
import YOUTUBELOGO from "../../assets/img/youtubeLogo.png";
import HomeItemsModel from "../../data/model/home_items";
import { getHomeItems } from "../../data/services/home_service";

export default function Slider() {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [homeItems, setHomeItems] = useState<HomeItemsModel[]>([]);
  const autoChangeInterval = 5000;
  const intervalRef = useRef<number | null>(null);

  useEffect(() => {
    const fetchItems = async () => {
      const { homeItems } = await getHomeItems();
      setHomeItems(homeItems || []);
    };
  
    fetchItems();
  }, []);
  
  
  useEffect(() => {
    if (homeItems.length > 0) {
      intervalRef.current = window.setInterval(handleNextSlide, autoChangeInterval);
    }
  
    return () => clearInterval(intervalRef.current!);
  }, [homeItems]);

  const handleNextSlide = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % homeItems.length);
    console.log(homeItems.length, "Tamanho HomeItens");
  };

  const handlePrevSlide = () => {
    setCurrentImageIndex(
      (prevIndex) => (prevIndex - 1 + homeItems.length) % homeItems.length
    );
  };

  

  const resetTimer = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
    intervalRef.current = window.setInterval(handleNextSlide, autoChangeInterval);
  };

  const imageStyles = [
    { height: "350px", width: "400px" },
    { height: "300px", width: "500px" },
    { height: "340px", width: "500px" },
  ];

  const isMobile = window.innerWidth <= 768;

  return (
    <SliderContainer>
      <ButtonIcon
        onClick={() => {
          handlePrevSlide();
          resetTimer();
        }}
      >
        <IoIosArrowDropleft size={44} />
      </ButtonIcon>
      <TitleColumn>
        <WelcomeText>SEJA BEM VINDO A</WelcomeText>
        <AbaceText>ABACE</AbaceText>
        <SubtitleText>A ASSOCIAÇÃO QUE CRIA LAÇOS</SubtitleText>
        <WrapButtonAndPagination>
          {homeItems.length > 0 && (
            <AppButton
              target="_blank"
              rel="noopener noreferrer"
              href={homeItems[currentImageIndex]?.url_Botao || "#"} 
            >
              {homeItems[currentImageIndex]?.nome_Botao}
            </AppButton>
          )}
          <PaginationDots>
            {homeItems.map((_, index) => (
              <Dot key={index} active={index === currentImageIndex} />
            ))}
          </PaginationDots>
        </WrapButtonAndPagination>
        <SliderMediaIconsRow>
          <a
            rel="noopener noreferrer"
            target="_blank"
            href="https://www.instagram.com/abace.bacen/"
          >
            <img src={INSTAGRAMLOGO} alt="Instagram" />
          </a>
          <a
            rel="noopener noreferrer"
            target="_blank"
            href="https://www.facebook.com/aabace/?locale=pt_BR"
          >
            <img src={FACEBOOKLOGO} alt="Facebook" />
          </a>
          <a
            rel="noopener noreferrer"
            target="_blank"
            href="https://www.youtube.com/@AbaceAposentadosBacen"
          >
            <img src={YOUTUBELOGO} alt="YouTube" />
          </a>
        </SliderMediaIconsRow>
      </TitleColumn>
      {homeItems.length > 0 && (
        <CellphoneImage
          src={homeItems[currentImageIndex]?.slide_Home_Urls}
          style={{
            transition: "transform 0.9s ease-in-out",
            marginRight: "20px",
            transform: `translateX(-${currentImageIndex}%)`,
            ...imageStyles[currentImageIndex % imageStyles.length],
            // maxHeight: '370px',
            width: '400px'
          }}
        />
      )}
      <ButtonIconRight
        onClick={() => {
          handleNextSlide();
          resetTimer();
        }}
      >
        <IoIosArrowDropright size={44} />
      </ButtonIconRight>
    </SliderContainer>
  );
}
