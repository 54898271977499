import { useState, useEffect } from "react";
import YearCarouselBooks from "../../components/YearCarouselBooks/YearCarouselBooks";
import {
  BookClubFilterText,
  BookClubPageTitle,
  BookClubRow,
  BookClubSubText,
  FilterButtons,
  RowDescription,
  WrapBookImageAndText,
} from "../../styles/BookClub";
import { getBooks } from "../../data/services/bookClub_service";
import { endpoint } from "../../utils/Constants";

export default function BookClub() {
  const [selectedYear, setSelectedYear] = useState<string>("2024");
  const [selectedMonth, setSelectedMonth] = useState<string | null>(null);
  const [livros, setLivros] = useState<any[]>([]);
  const [filteredLivro, setFilteredLivro] = useState<any | null>(null);

  useEffect(() => {
    async function fetchLivros() {
      const { livros } = await getBooks();
      if (livros) {
        const livrosDoAno = livros.filter(
          (livro) => livro.ano === selectedYear
        );
        setLivros(livrosDoAno);
        setFilteredLivro(null); 
        setSelectedMonth(null); 
      }
    }

    fetchLivros();
  }, [selectedYear]);

  const handleMonthClick = (month: string) => {
    setSelectedMonth(month);

    const livroDoMes = livros.find((livro) => livro.mes === month);
    setFilteredLivro(livroDoMes || null);
  };

  return (
    <>
      <BookClubPageTitle>Clube do livro</BookClubPageTitle>
      <BookClubSubText>
        CLIQUE NAS SETAS E ESCOLHA O ANO QUE DESEJA.
      </BookClubSubText>

      {/* Componente de Carrossel de Anos */}
      <YearCarouselBooks
        selectedYear={selectedYear}
        setSelectedYear={setSelectedYear}
      />

      <BookClubFilterText>
        Clique nos filtros abaixo para ter acesso ao livro lido de cada mês.
      </BookClubFilterText>

      <BookClubRow>
        {[
          "Janeiro",
          "Fevereiro",
          "Março",
          "Abril",
          "Maio",
          "Junho",
          "Julho",
          "Agosto",
          "Setembro",
          "Outubro",
          "Novembro",
          "Dezembro",
        ].map((month) => (
          <FilterButtons
            key={month}
            onClick={() => handleMonthClick(month)}
            isSelected={selectedMonth === month} 
          >
            {month}
          </FilterButtons>
        ))}
      </BookClubRow>

      {selectedMonth ? (
        filteredLivro ? (
          <WrapBookImageAndText>
            <img
              src={`${endpoint}${filteredLivro.foto_livro?.url}`}
              alt="Livro"
            />
            <RowDescription>
              <h1>{filteredLivro.titulo}</h1>
              <p
                dangerouslySetInnerHTML={{ __html: filteredLivro.descricao! }}
              />
            </RowDescription>
          </WrapBookImageAndText>
        ) : (
          <WrapBookImageAndText>
            <RowDescription>
              <h1>
                Nenhum livro encontrado para {selectedMonth} de {selectedYear}
              </h1>
            </RowDescription>
          </WrapBookImageAndText>
        )
      ) : (
        <WrapBookImageAndText>
          <RowDescription>
            <h1>Selecione um mês para ver o livro correspondente</h1>
          </RowDescription>
        </WrapBookImageAndText>
      )}
    </>
  );
}
