import React, { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import {
  ActivitiesCardsSubText,
  ActivitiesCardsTitle,
  ActivitiesCircularIcon,
  ActivitiesPageCards,
  ActivitiesPageStyle,
  ActivitiesPageTitle,
  ActivitiesSubText,
  HorizontalDivision,
  WrapActivitiesPageCars,
  WrapContainer,
} from "../../styles/ActivitiesPage";
import { FaCarSide } from "react-icons/fa6";
import { GiInjustice } from "react-icons/gi";
import Footer from "../../components/Footer/Footer";
import { useNavigate } from "react-router-dom";
import { ACTIVITIES } from "../../core/app-urls";
import ActivitiesListModel from "../../data/model/activities_list";
import { getPartners } from "../../data/services/activities_service";


export default function ActivitiesPage() {
  const navigate = useNavigate();
  const [partners, setPartners] = useState<ActivitiesListModel[]>([]);

  useEffect(() => {
    const fetchPartners = async () => {
      const response = await getPartners();
      setPartners(response.partners);
    };

    fetchPartners();
  }, []);

  const handleActivitiesDetails = (id: number) => {
    navigate(`${ACTIVITIES}/${id}`);
  };

  return (
    <>
      <Header />
      <ActivitiesPageStyle>
        <HorizontalDivision />
        <WrapContainer>
          <ActivitiesPageTitle>Parcerias</ActivitiesPageTitle>
          <ActivitiesSubText>
            Aqui você tem acesso a todas nossas atividades, parcerias, projetos
            e muito mais!
          </ActivitiesSubText>
          <WrapActivitiesPageCars>
            {partners.map((partner) => (
              <ActivitiesPageCards
                key={partner.id}
                onClick={() => handleActivitiesDetails(partner.id!)}
              >
                <ActivitiesCircularIcon>
                  <FaCarSide size={30} color="#1270B0" />
                </ActivitiesCircularIcon>
                <ActivitiesCardsTitle>{partner.titulo_Parceiro}</ActivitiesCardsTitle>
                <ActivitiesCardsSubText>
                  {partner.sub_Titulo_Parceiro}
                </ActivitiesCardsSubText>
              </ActivitiesPageCards>
            ))}
          </WrapActivitiesPageCars>
        </WrapContainer>
      </ActivitiesPageStyle>
      <Footer />
    </>
  );
}
