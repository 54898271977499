import ActivitiesListModel from "../model/activities_list";
import { fetchActivitiesList } from "../provider/api";

interface fetchActivitiesItemsResponse {
    activitiesList: ActivitiesListModel[] | null; 
}

export async function getPartners(): Promise<{ partners: ActivitiesListModel[] }> {
    try {
        const responseData = await fetchActivitiesList();

        const items = responseData.data.map((item: any) => {
            const attributes = item.attributes;
            const imageUrl = attributes.imagem_parceiro?.data?.attributes?.url || null;

            return new ActivitiesListModel(
                item.id,
                attributes.titulo_parceiro,
                attributes.sub_titulo_parceiro,
                attributes.descricao_parceiro,
                imageUrl,
                attributes.createdAt,
                attributes.updatedAt,
                attributes.publishedAt,
                attributes.titulo_card,
                attributes.sub_titulo_card,
            );
        });

        return { partners: items };

    } catch (error: any) {
        console.error(`Erro (${error.response?.status}) ao buscar parceiros`);
        console.log(error.toString());
        return { partners: [] }; 
    }
}