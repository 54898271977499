import styled, { css } from "styled-components";

export const FirstRowAbace = styled.div`
  width: 100%;
  height: 500px;
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const WrapContentFirstRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 350px;
  width: 90%;
  gap: 100px;

  @media screen and (max-width: 1024px) {
      width: 100%;
      flex-direction: column;
      height: 500px;
      gap: 0;
  }
`;

export const VideoContainer = styled.div`
  position: relative;
  z-index: 1;

  @media screen and (max-width: 1024px) {
    /* display: none; */
  }
    
`;

export const ContainerBehindVideo = styled.div`
  position: absolute;
  top: 150px; 
  left: -50px; 
  width: 350px; 
  height: 380px; 
  background-color: #eeeeee;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
  z-index: 0; 

  @media screen and (max-width: 1024px) {
    display: none;
  }
`;

export const ContainerBehindVideo2 = styled.div`
  position: absolute;
  top: 180px; 
  left: 40px; 
  width: 350px; 
  height: 380px; 
  background-color: #eeeeee;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
  z-index: 0; 

  @media screen and (max-width: 1024px) {
    display: none;
  }
`;


export const ImageFirstRow = styled.video`
  height: 450px;
  width: 350px;
  border-radius: 5px;
  object-fit: cover;
  align-self: center;
  margin-top: 90px;
  position: relative; 
  z-index: 1; 

  @media screen and (max-width: 1024px) {
    height: 390px;
    width: 290px;
  }
`;

export const WrapTextsFirstRow = styled.div`
  width: 40%;
  height: 350px;
  display: flex;
  flex-direction: column;
  align-self: center;
  margin-bottom: 50px;

  @media screen and (max-width: 1024px) {
        display: none;
    }
`;

export const AbaceTitle = styled.h1`
  font-family: "Baloo Bhai 2", sans-serif;
  font-size: 35pt;
  font-weight: 500;
  color: #414c80;
  margin-left: 35px;
`;

interface AbaceTextProps {
  responsive?: boolean;
}

export const AbaceText = styled.h1<AbaceTextProps>`
  font-family: "Baloo Bhai 2", sans-serif;
  font-size: 12pt;
  font-weight: 500;
  height: 90%;
  color: black;
  text-align: justify;
  margin-left: 35px;
  margin-top: 0;

  ${props =>
    props.responsive &&
    css`
      @media (max-width: 768px) {
        font-size: 12pt;
      }

      @media (max-width: 480px) {
        font-size: 10pt;
      }
    `}
`;

export const HorizontalDivision = styled.div`
  width: 100%;
  border-bottom: 1px solid #000000;
  margin-bottom: 45px;
  margin-top: 98px;

  @media screen and (max-width: 1024px) {
    border-bottom: 0;
  }
`;

export const Ellipse = styled.div`
  width: 250px;
  height: 80px;
  background-color: #b8c9dd;
  border-radius: 50px / 40px;
  position: absolute;
  align-items: center;
  justify-content: center;
  top: 678px;
  left: 50%;
  z-index: 2;
  transform: translate(-50%, -50%);

  @media screen and (max-width: 1024px) {
    position: absolute;
    top: 650px;
  }
`;

export const EllipseText = styled.h1`
  color: black;
  font-family: "Baloo Bhai 2", sans-serif;
  font-weight: 500;
  font-size: 20pt;
  text-align: center;
  align-self: center;
  margin-bottom: 10px;
`;
