import styled from "styled-components";

// Estilo do Header para Desktop
export const DesktopHeaderStyle = styled.header`
  height: 80px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  background-color: #DFEFF4;

  @media screen and (max-width: 1024px) {
    display: none;
  }
`;

// Estilo do Header para Mobile
export const MobileHeaderStyle = styled.header`
  height: 80px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #dfeff4;
  /* padding: 0 20px; */

  @media screen and (min-width: 1025px) {
    display: none;
  }
`;

export const LogoWrapper = styled.div`
  position: relative;
  display: inline-block;

  &:hover div {
    visibility: visible;
    opacity: 1;
  }
`;

export const LogoHoverText = styled.div`
  visibility: hidden;
  opacity: 0;
  width: 60px;
  background-color: #b7b7b7;
  color: white;
  text-align: center;
  border-radius: 5px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: -35px;
  left: 50%;
  transform: translateX(-50%);
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;

  @media screen and (max-width: 1024px) {
    width: 80px;
    bottom: -25px;
  }
`;

export const LogoStyle = styled.img`
  width: 100px;

  @media screen and (max-width: 1024px) {
    width: 60px;
    padding-left: 10px;
    padding-top: 5px;
  }
`;

export const MenuIcon = styled.div`
  font-size: 28px;
  margin-right: 10px;
  cursor: pointer;
`;

export const MobileMenu = styled.div`
  position: absolute;
  top: 80px;
  left: 0;
  width: 100%;
  background-color: #ffffff;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.5);
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  padding-bottom: 10px;
`;

export const MobileMenuItem = styled.button`
  background-color: transparent;
  border: none;
  padding: 10px 20px;
  text-align: left;
  font-size: 16px;
  color: #000;
  cursor: pointer;
  font-family: "Belleza", sans-serif;
  font-weight: 400;

  &:hover {
    background-color: #f0f0f0;
  }
`;

export const WrapHeaderItemsLine = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const HeaderItems = styled.button`
  background-color: transparent;
  font-weight: bold;
  font-size: 13pt;
  height: 40px;
  padding: 0 12px;
  color: #000000;
  border: transparent;
  border-radius: 8px;
  cursor: pointer;
  font-family: "Belleza", sans-serif;
  font-weight: 400;

  @media screen and (max-width: 1024px) {
    font-size: 1rem;
  }
`;

interface HorizontalSize {
  size: boolean;
}

export const HorizontalLine = styled.div<HorizontalSize>`
  width: ${(props) => (props.size ? '205px' : '85px')};
  border-bottom: 3px solid #1270B0;
`;

export const WrapAssociatedButtons = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
`;

export const AssociatedButtons = styled.button`
  background-color: #6675c1;
  font-size: 15pt;
  height: 28px;
  padding: 0 12px;
  color: #e0e1dd;
  border: transparent;
  border-radius: 8px;
  cursor: pointer;
  font-family: "Baloo Bhai 2", sans-serif;
  font-weight: 500;

  @media screen and (max-width: 1024px) {
    width: 70%;
    height: 35px;
    align-self: center;
  }

  a {
    text-decoration: none;
    color: #e0e1dd;
  }
`;

export const AssociatedButtons2 = styled.button`
  background-color: transparent;
  font-size: 12pt;
  height: 28px;
  padding: 0 12px;
  border-width: 1px;
  color: #6675c1;
  border-color: #414c80;
  border-radius: 8px;
  cursor: pointer;
  font-family: "Commissioner", sans-serif;
  font-weight: 500;
  font-style: italic;

  @media screen and (max-width: 1024px) {
    width: 70%;
    height: 35px;
    align-self: center;
  }

  a {
    text-decoration: none;
    color: #6675c1;
  }
`;
