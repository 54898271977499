import styled from "styled-components";

export const ActivitiesPageStyle = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

export const HorizontalDivision = styled.div`
  width: 100%;
  border-bottom: 2px solid #1270B0;
  position: absolute;
  top: 370px;

  @media screen and (max-width: 1024px) {
    display: none;
  }
`;

export const WrapContainer = styled.div`
    width: 75%;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 1024px) {
      width: 95%;
      justify-content: center;
      align-items: center;
    }
`

export const ActivitiesPageTitle = styled.h1`
  font-family: "Baloo Bhai 2", sans-serif;
  font-size: 35pt;
  font-weight: 500;
  color: #414c80;
  align-self: flex-start;
  margin-bottom: 0;
`;

export const ActivitiesSubText = styled.h1`
  font-family: "Actor", sans-serif;
  font-size: 12pt;
  font-weight: 400;
  color: black;
  align-self: flex-start;
  margin-top: 0;
`;

export const WrapActivitiesPageCars = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 100px;
    margin-bottom: 200px;
    

    @media screen and (max-width: 1024px) {
      grid-template-columns: repeat(2, 1fr);
      gap: 25px;
    }
`

export const ActivitiesPageCards = styled.div`
    background-color: #DFEFF4;
    width: auto;
    height: 200px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
    position: relative;
    margin-top: 60px;
    cursor: pointer;
    padding: 5px;
`

export const ActivitiesCardsTitle = styled.h1`
  font-family: "Baloo Bhai 2", sans-serif;
  font-size: 15pt;
  font-weight: 500;
  color: #1270B0;
  align-self: center;
  text-align: center;
  margin-bottom: 0;
  line-height: 25px;
`;

export const ActivitiesCardsSubText = styled.h1`
  font-family: "Actor", sans-serif;
  font-size: 9pt;
  font-weight: 400;
  color: black;
  align-self: center;
  text-align: center;
  margin-top: 0;
  padding: 9px;
`;

export const ActivitiesCircularIcon = styled.div`
    background-color: white;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    position: absolute;
    top: -13%;
    left: 33%;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
    justify-content: center;
    align-items: center;
    /* display: flex; */
    display: none;
`
