import { endpoint } from "../../utils/Constants";
import LinksHomeModel, { IconeLinkModel } from "../model/links_home";
import PartnerModel, { IconeParceiroModel } from "../model/partners_home";
import { fetchLinksHome, fetchPartnersHome } from "../provider/api";

interface fetchPartnersResponse {
  partners: PartnerModel[]; 
}

interface fetchLinksHomeResponse {
  linksHome: LinksHomeModel[]; 
}

export async function getPartners(): Promise<fetchPartnersResponse> {
  try {
    const responseData = await fetchPartnersHome();
    
    
    const partners = responseData.data.map((data: any) => {
      const attributes = data.attributes;
      const iconeParceiroData = attributes.icone_parceiro.data.attributes;

      const imageFormats = iconeParceiroData.formats;
      const imageFormatsModel = {
        thumbnail: imageFormats.thumbnail
          ? {
              name: imageFormats.thumbnail.name,
              hash: imageFormats.thumbnail.hash,
              ext: imageFormats.thumbnail.ext,
              mime: imageFormats.thumbnail.mime,
              path: imageFormats.thumbnail.path,
              width: imageFormats.thumbnail.width,
              height: imageFormats.thumbnail.height,
              size: imageFormats.thumbnail.size,
              url: `${endpoint}${imageFormats.thumbnail.url}`,
            }
          : null,
        large: imageFormats.large
          ? {
              name: imageFormats.large.name,
              hash: imageFormats.large.hash,
              ext: imageFormats.large.ext,
              mime: imageFormats.large.mime,
              path: imageFormats.large.path,
              width: imageFormats.large.width,
              height: imageFormats.large.height,
              size: imageFormats.large.size,
              url: `${endpoint}${imageFormats.large.url}`,
            }
          : null,
        medium: imageFormats.medium
          ? {
              name: imageFormats.medium.name,
              hash: imageFormats.medium.hash,
              ext: imageFormats.medium.ext,
              mime: imageFormats.medium.mime,
              path: imageFormats.medium.path,
              width: imageFormats.medium.width,
              height: imageFormats.medium.height,
              size: imageFormats.medium.size,
              url: `${endpoint}${imageFormats.medium.url}`,
            }
          : null,
        small: imageFormats.small
          ? {
              name: imageFormats.small.name,
              hash: imageFormats.small.hash,
              ext: imageFormats.small.ext,
              mime: imageFormats.small.mime,
              path: imageFormats.small.path,
              width: imageFormats.small.width,
              height: imageFormats.small.height,
              size: imageFormats.small.size,
              url: `${endpoint}${imageFormats.small.url}`,
            }
          : null,
      };

      return new PartnerModel(
        data.id,
        attributes.url_parceiro,
        attributes.createdAt,
        attributes.updatedAt,
        attributes.publishedAt,
        new IconeParceiroModel(
          iconeParceiroData.id,
          iconeParceiroData.name,
          iconeParceiroData.alternativeText,
          iconeParceiroData.caption,
          iconeParceiroData.width,
          iconeParceiroData.height,
          imageFormatsModel,
          iconeParceiroData.hash,
          iconeParceiroData.ext,
          iconeParceiroData.mime,
          iconeParceiroData.size,
          `${endpoint}${iconeParceiroData.url}`,
          iconeParceiroData.previewUrl,
          iconeParceiroData.provider,
          iconeParceiroData.provider_metadata,
          iconeParceiroData.createdAt,
          iconeParceiroData.updatedAt
        )
      );
    });

    return { partners }; 
  } catch (error: any) {
    console.error(`Erro (${error.response?.status}) ao buscar parceiros`);
    console.log(error.toString());
    return { partners: [] }; 
  }
}

export async function getLinksHome(): Promise<fetchLinksHomeResponse> {
    try {
      const responseData = await fetchLinksHome();
  
      const linksHome = responseData.data.map((data: any) => {
        const attributes = data.attributes;
        const logoData = attributes.logo?.data?.attributes || {}; // Garante que o logo não seja nulo
  
        // Checa se `formats` está presente, caso contrário define como null
        const imageFormats = logoData.formats || {
          thumbnail: null,
          large: null,
          medium: null,
          small: null,
        };
  
        const imageFormatsModel = {
          thumbnail: imageFormats.thumbnail
            ? {
                name: imageFormats.thumbnail.name,
                hash: imageFormats.thumbnail.hash,
                ext: imageFormats.thumbnail.ext,
                mime: imageFormats.thumbnail.mime,
                path: imageFormats.thumbnail.path,
                width: imageFormats.thumbnail.width,
                height: imageFormats.thumbnail.height,
                size: imageFormats.thumbnail.size,
                url: `${endpoint}${imageFormats.thumbnail.url}`,
              }
            : null,
          large: imageFormats.large
            ? {
                name: imageFormats.large.name,
                hash: imageFormats.large.hash,
                ext: imageFormats.large.ext,
                mime: imageFormats.large.mime,
                path: imageFormats.large.path,
                width: imageFormats.large.width,
                height: imageFormats.large.height,
                size: imageFormats.large.size,
                url: `${endpoint}${imageFormats.large.url}`,
              }
            : null,
          medium: imageFormats.medium
            ? {
                name: imageFormats.medium.name,
                hash: imageFormats.medium.hash,
                ext: imageFormats.medium.ext,
                mime: imageFormats.medium.mime,
                path: imageFormats.medium.path,
                width: imageFormats.medium.width,
                height: imageFormats.medium.height,
                size: imageFormats.medium.size,
                url: `${endpoint}${imageFormats.medium.url}`,
              }
            : null,
          small: imageFormats.small
            ? {
                name: imageFormats.small.name,
                hash: imageFormats.small.hash,
                ext: imageFormats.small.ext,
                mime: imageFormats.small.mime,
                path: imageFormats.small.path,
                width: imageFormats.small.width,
                height: imageFormats.small.height,
                size: imageFormats.small.size,
                url: `${endpoint}${imageFormats.small.url}`,
              }
            : null,
        };
  
        return new LinksHomeModel(
          data.id,
          attributes.link,
          attributes.createdAt,
          attributes.updatedAt,
          attributes.publishedAt,
          new IconeLinkModel(
            logoData.id || null,
            logoData.name || null,
            logoData.alternativeText || null,
            logoData.caption || null,
            logoData.width || null,
            logoData.height || null,
            imageFormatsModel,
            logoData.hash || null,
            logoData.ext || null,
            logoData.mime || null,
            logoData.size || null,
            logoData.url ? `${endpoint}${logoData.url}` : null,
            logoData.previewUrl || null,
            logoData.provider || null,
            logoData.provider_metadata || null,
            logoData.createdAt || null,
            logoData.updatedAt || null
          )
        );
      });
  
      return { linksHome }; 
    } catch (error: any) {
      console.error(`Erro (${error.response?.status}) ao buscar links`);
      console.log(error.toString());
      return { linksHome: [] }; 
    }
  }
  
