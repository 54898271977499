import {
  FirstRowPartners,
  HorizontalDivision,
  HorizontalDivision2,
  PartnersLogos,
  PartnersLogos2,
  PartnersLogos3,
  PartnersLogos4,
  PartnersStyle,
  RectangleDivisor,
  RectangleDivisor2,
  RectangleDivisorText,
  SecondRowPartners,
} from "../../styles/Partners";
import FENASBAC from "../../assets/img/fenasbac.png";
import AAFBB from "../../assets/img/aafbb.png";
import CENTRUS from "../../assets/img/centrus.png";
import ASBAC from "../../assets/img/asbac.png";
import BACEN from "../../assets/img/bacen.png";
import BCSAUDE from "../../assets/img/bcSaude.png";
import SISBACEN from "../../assets/img/sisbacen.png";
import { useEffect, useState } from "react";
import PartnerModel from "../../data/model/partners_home";
import {
  getLinksHome,
  getPartners,
} from "../../data/services/partners_home_service";
import LinksHomeModel from "../../data/model/links_home";

export default function Partners() {
  const [partners, setPartners] = useState<PartnerModel[]>([]);
  const [links, setLinks] = useState<LinksHomeModel[]>([]);

  useEffect(() => {
    const fetchPartnersData = async () => {
      const response = await getPartners();
      setPartners(response.partners);
    };
    const fetchLinksHomeData = async () => {
      const responseLinks = await getLinksHome();
      setLinks(responseLinks.linksHome);
    };
    fetchPartnersData();
    fetchLinksHomeData();
  }, []);

  return (
    <>
      <HorizontalDivision>
        <RectangleDivisor>
          <RectangleDivisorText>PARCEIROS ABACE</RectangleDivisorText>
        </RectangleDivisor>
      </HorizontalDivision>
      <PartnersStyle>
        <FirstRowPartners>
          {partners.map((partner) => (
            <a
              key={partner.id}
              href={partner.url_parceiro!}
              target="_blank"
              rel="noopener noreferrer"
            >
              <PartnersLogos
                src={partner.icone_parceiro?.url!}
              />
            </a>
          ))}
        </FirstRowPartners>
        <HorizontalDivision2>
          <RectangleDivisor2>
            <RectangleDivisorText>LINKS ÚTEIS</RectangleDivisorText>
          </RectangleDivisor2>
        </HorizontalDivision2>
        <SecondRowPartners>
          {links.map((link) => (
            <a
              key={link.id}
              href={link.link!}
              target="_blank"
              rel="noopener noreferrer"
            >
              <PartnersLogos src={link.logo?.url!} />
            </a>
          ))}
        </SecondRowPartners>
      </PartnersStyle>
    </>
  );
}
