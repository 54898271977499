import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { ABOUT, ACTIVITIES, EVENTS, GALLERY, GALLERYITEMS, LANDING_PAGE, NEWS, RELATIONSHIP } from '../core/app-urls';
import Home from '../pages/Home';
import About from '../pages/About';
import NewsPage from '../pages/NewsPage';
import NewsDetailsPage from '../pages/NewsPage/NewsDetailsPage';
import EventsPage from '../pages/EventsPage';
import GalleryPage from '../pages/GalleryPage';
import GalleryItemsPage from '../pages/GalleryItemsPage';
import ActivitiesPage from '../pages/ActivitiesPage';
import ActivitiesDatailsPage from '../pages/ActivitiesPage/ActivitiesDetailsPage';
import RelationshipDetailsPage from '../pages/RelationshipHome/RelationshipDetails';
import ScrollToTop from '../components/ScrollToTop/ScrollToTop';


export default function AppRoutes() {
    return (
        <Router>
            <ScrollToTop />
            <Routes>
                <Route path={LANDING_PAGE} element={<Home />} > </Route>
                <Route path={ABOUT} element={<About />} > </Route>
                <Route path={NEWS} element={<NewsPage />} />
                <Route path={`${NEWS}/:id`} element={<NewsDetailsPage />} /> 
                <Route path={EVENTS} element={<EventsPage />} />
                <Route path={GALLERY} element={<GalleryPage />} />
                <Route path={`${GALLERYITEMS}/:albumId`} element={<GalleryItemsPage />} />
                <Route path={ACTIVITIES} element={<ActivitiesPage />} />
                <Route path={`${ACTIVITIES}/:id`} element={<ActivitiesDatailsPage />} />
                <Route path={`${RELATIONSHIP}/:id`} element={<RelationshipDetailsPage />} />
            </Routes>
        </Router>
    );
}