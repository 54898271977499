import { useState, useEffect } from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import NEWSEXAMPLE from "../../assets/img/newsExample.png";
import {
  CategorySubTag,
  CategoryTag,
  ContentMainCard,
  ContentSubCard,
  FilterButtons,
  ImageMainCard,
  ImageSubCard,
  KnowMoreMainCard,
  NewsPageContainer,
  NewsPageFilterRow,
  NewsPageFilterText,
  NewsPageMainCards,
  NewsPageSubCards,
  NewsPageTitle,
  RightPartMainCard,
  StyledArrowIcon,
  TitleMainCard,
  TitleSubCard,
  TwitterTimeLine,
  WrapCards,
  WrapCardsAndTwitter,
  WrapContent,
  WrapGrid,
  WrapKnowMore,
  WrapVideos,
} from "../../styles/NewsPage";
import { NEWS } from "../../core/app-urls";
import NEWS4 from "../../assets/img/emergencia.png";
import NewsModel from "../../data/model/news";
import { getNews } from "../../data/services/news";
import { endpoint } from "../../utils/Constants";

export default function NewsPage() {
  interface VideoItem {
    id: { videoId: string };
    snippet: {
      title: string;
      description: string;
      thumbnails: { high: { url: string } };
    };
  }

  const [selectedFilter, setSelectedFilter] = useState("Todas");
  const [newsList, setNewsList] = useState<NewsModel[]>([]);
  const [videos, setVideos] = useState<VideoItem[]>([]);

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const response = await getNews();
        setNewsList(response.news);
      } catch (error) {
        console.error("Erro ao carregar notícias", error);
      }
    };

    fetchNews();
  }, []);

  const filteredNewsList =
    selectedFilter === "Todas"
      ? newsList
      : newsList.filter((news) => news.tag === selectedFilter);

  const mainCardsNewsList = filteredNewsList.slice(0, 3);
  const gridNewsList = filteredNewsList.slice(3);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://platform.twitter.com/widgets.js";
    script.charset = "utf-8";
    script.async = true;
    script.setAttribute("data-lang", "pt");
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  // useEffect(() => {
  //   const fetchPlaylists = async () => {
  //     try {
  //       const response = await axios.get<{
  //         items: VideoItem[];
  //       }>( /* URL e parâmetros */);
  //       setVideos(response.data.items);
  //       console.log("Deu certo.");
  //       console.log(response.data.items);
  //     } catch (error) {
  //       console.error("Erro ao buscar playlists do YouTube:", error);
  //     }
  //   };

  //   fetchPlaylists();
  // }, []);

  return (
    <>
      <Header />
      <NewsPageContainer>
        <WrapContent>
          <NewsPageTitle>Nossas notícias</NewsPageTitle>
          <NewsPageFilterText>
            Clique nos filtros abaixo para ter acesso às notícias do seu
            interesse.
          </NewsPageFilterText>
          <NewsPageFilterRow>
            <FilterButtons
              onClick={() => setSelectedFilter("Todas")}
              style={{
                borderColor: selectedFilter === "Todas" ? "#FF8787" : "#fff",
                color: selectedFilter === "Todas" ? "#FF8787" : "#000",
              }}
            >
              {" "}
              Todas
            </FilterButtons>
            <FilterButtons
              onClick={() => setSelectedFilter("parcerias")}
              newColor="#1270B0"
              style={{
                borderColor:
                  selectedFilter === "parcerias" ? "#1270B0" : "#fff",
                color: selectedFilter === "parcerias" ? "#1270B0" : "#000",
              }}
            >
              Parcerias
            </FilterButtons>
            <FilterButtons
              onClick={() => setSelectedFilter("eventos")}
              newColor="#85A261"
              style={{
                borderColor: selectedFilter === "eventos" ? "#85A261" : "#fff",
                color: selectedFilter === "eventos" ? "#85A261" : "#000",
              }}
            >
              Eventos
            </FilterButtons>
            <FilterButtons
              onClick={() => setSelectedFilter("BC Saúde")}
              newColor="pink"
              style={{
                borderColor: selectedFilter === "BC Saúde" ? "pink" : "#fff",
                color: selectedFilter === "BC Saúde" ? "pink" : "#000",
              }}
            >
              BC Saúde
            </FilterButtons>
            <FilterButtons
              onClick={() => setSelectedFilter("reuniões")}
              newColor="gray"
              style={{
                borderColor: selectedFilter === "reuniões" ? "gray" : "#fff",
                color: selectedFilter === "reuniões" ? "gray" : "#000",
              }}
            >
              Reuniões
            </FilterButtons>
          </NewsPageFilterRow>
        </WrapContent>

        <WrapCardsAndTwitter>
          <WrapCards>
            {mainCardsNewsList.map((news, index) => (
              <NewsPageMainCards key={index} href={`${NEWS}/${news.id}`}>
                <ImageMainCard src={`${endpoint}${news.imagem_noticia}` || NEWSEXAMPLE} />
                <RightPartMainCard>
                  <CategoryTag
                    newColor={getTagColor(news.tag)}
                  >
                    {news.tag}
                  </CategoryTag>
                  <TitleMainCard>{news.titulo}</TitleMainCard>
                  <ContentMainCard>{news.resumo}</ContentMainCard>
                  <WrapKnowMore>
                    <KnowMoreMainCard>
                      Leia a matéria na íntegra
                    </KnowMoreMainCard>
                    <StyledArrowIcon size={23} />
                  </WrapKnowMore>
                </RightPartMainCard>
              </NewsPageMainCards>
            ))}
          </WrapCards>
          <TwitterTimeLine>
            <a
              className="twitter-timeline"
              href="https://twitter.com/BancoCentralBR?ref_src=twsrc%5Etfw"
              data-lang="pt"
            >
              Tweets by BancoCentralBR
            </a>
          </TwitterTimeLine>
        </WrapCardsAndTwitter>

        {/* <WrapContent>
          <WrapVideos>
            <ul>
              {videos.map((video, index) => (
                <li key={index}>
                <a
                  href={`https://www.youtube.com/watch?v=${video.id.videoId}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={video.snippet.thumbnails.high.url}
                    alt="Thumbnail do Vídeo"
                  />
                  <p>{video.snippet.title}</p>
                </a>
              </li>
              ))}}
            </ul>
          </WrapVideos>
        </WrapContent> */}

        <WrapGrid>
          {gridNewsList.map((news, index) => (
            <NewsPageSubCards key={index} href={`${NEWS}/${news.id}`}>
              <ImageSubCard src={`${endpoint}${news.imagem_noticia}`} />
              <RightPartMainCard>
                <CategorySubTag
                  newColor={getTagColor(news.tag)}
                >
                  {news.tag}
                </CategorySubTag>
                <TitleSubCard>{news.titulo}</TitleSubCard>
                <ContentSubCard>{news.resumo}</ContentSubCard>
                <WrapKnowMore>
                  <TitleSubCard>Leia a matéria na íntegra</TitleSubCard>
                  <StyledArrowIcon size={20} />
                </WrapKnowMore>
              </RightPartMainCard>
            </NewsPageSubCards>
          ))}
        </WrapGrid>
      </NewsPageContainer>
      <Footer />
    </>
  );
}

function getTagColor(selectedCategory: string): string {
  switch (selectedCategory) {
    case "parcerias":
      return "#1270B0";
    case "eventos":
      return "#85A261";
    case "BC Saúde":
      return "pink";
    case "Reuniões":
      return "gray";
    default:
      return "";
  }
}


