import NewsModel from "../model/news";
import { fetchNews, fetchNewsHome } from "../provider/api";

interface fetchNewsItemsResponse {
    newsList: NewsModel[] | null;
}

export async function getNews(): Promise<{ news: NewsModel[] }> {
    try {
        const responseData = await fetchNews();

        const items = responseData.data.map((item: any) => {
            const attributes = item.attributes;
            const imageUrl = attributes.imagem_noticia?.data?.attributes?.url || null;

            return new NewsModel(
                item.id,
                attributes.titulo,
                attributes.descricao,
                attributes.resumo,
                attributes.tag,
                attributes.createdAt,
                attributes.updatedAt,
                attributes.publishedAt,
                imageUrl
            );
        });

        return { news: items };

    } catch (error: any) {
        console.error(`Erro (${error.response?.status}) ao buscar notícias`);
        console.log(error.toString());
        return { news: [] }; 
    }
}

export async function getNewsHome(): Promise<{ news: NewsModel[] }> {
    try {
        const responseData = await fetchNewsHome();

        const items = responseData.data.map((item: any) => {
            const attributes = item.attributes;
            const imageUrl = attributes.imagem_noticia?.data?.attributes?.url || null;

            return new NewsModel(
                item.id,
                attributes.titulo,
                attributes.descricao,
                attributes.resumo,
                attributes.tag,
                attributes.createdAt,
                attributes.updatedAt,
                attributes.publishedAt,
                imageUrl
            );
        });

        return { news: items };

    } catch (error: any) {
        console.error(`Erro (${error.response?.status}) ao buscar notícias`);
        console.log(error.toString());
        return { news: [] }; 
    }
}
