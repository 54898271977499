import styled from "styled-components";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

export const HorizontalDivision = styled.div`
    width: 100%;
    border-bottom: 1px solid #000000; 
    margin-bottom: 45px;

    @media screen and (max-width: 1024px) {
        display: none;    
    }
`;

export const Ellipse = styled.div`
    width: 400px;
    height: 90px;
    background-color: #FFFFFF;
    border-radius: 50px / 40px; 
    position: absolute;
    align-items: center;
    justify-content: center;
    top: 1312px;
    left: 50%;
    z-index: 2;
    transform: translate(-50%, -50%);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);

    @media screen and (max-width: 1024px) {
      /* display: none;     */
    }
`;

export const EllipseText = styled.h1`
    color: #1270B0;
    font-family: "Belleza", sans-serif;
    font-weight: 400;
    font-size: 15pt;
    text-align: center;
    align-self: center;
    padding-left: 1px;
    margin-bottom: 10px;
    padding-top: 2px;
`

export const EllipseSecondText = styled.h1`
    color: #1270B0;
    font-family: "Belleza", sans-serif;
    font-weight: 400;
    font-size: 20pt;
    text-align: center;
    align-self: center;
    padding-left: 2px;
    margin-top: 0;
`

export const EventStyle = styled.div`
    display: flex;
    flex-direction: column;
`

export const EventPageTitle = styled.h1`
    color: black;
    font-family: "Cinzel", sans-serif;
    font-weight: 700;
    font-size: 15pt;
    /* margin-left: px; */
    margin-top: 0px;
    align-self: center;
    margin-right: 870px;
    /* margin-top: 2px;
    margin-bottom: 4px; */

    @media screen and (max-width: 1024px) {
      display: none;    
    }
`

export const EventPageSub = styled.h1`
    color: black;
    font-family: "Actor", sans-serif;
    font-weight: 400;
    font-size: 10pt;
    margin-left: 80px;
    margin-top: 0;
    align-self: center;
    margin-right: 798px;
    /* margin-top: 2px;
    margin-bottom: 4px; */

    @media screen and (max-width: 1024px) {
      display: none;    
    }
`

export const WrapCalendarAndEvents = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-content: center;
    width: 100%;
    height: 400px;
    /* margin-left: 100px; */
    /* margin-right: 380px; */
`

export const EventTitle = styled.h1`
    color: #1270B0;
    font-family: "Cinzel", sans-serif;
    font-weight: 600;
    font-size: 15pt;
    margin-top: 2px;
    margin-bottom: 4px;
`

export const StyledEventDate = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f0f8ff; 
  color: #4682b4;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  border-radius: 50%;
  height: 60px;
  width: 60px;
  margin-right: 15px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); 
`;

export const EventCard = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  background-color: #ffffff; 
  border-radius: 8px;
  margin-bottom: 15px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); 
  transition: transform 0.3s ease;
  
  &:hover {
    transform: translateY(-5px); 
  }
`;

export const EventInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

export const EventDescrip = styled.h1`
    color: #000000;
    font-family: "Actor", sans-serif;
    font-weight: 400;
    font-size: 10pt;
    width: 70%;
`

export const WrapEventTitleAndDescrip = styled.div`
    display: flex;
    flex-direction: column;
    /* margin-left: 900px; */
`

export const WrapEventTitlesAllEvents = styled.div`
    display: flex;
    flex-direction: row;
    /* height: 50px; */
`

export const WrapEventList = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  max-height: 300px; 
  margin-left: 700px;
  width: 30%;
`;


export const StyledCalendar = styled(Calendar)`
    border: none;
    position: absolute;
    left: 20%; 
    z-index: 1;
    background-color: transparent;

    .react-calendar__tile--active {
        border-radius: 5px;
        color: inherit; 
    }

    .react-calendar__tile.evento {
        background-color: #78D700;
        opacity: 0.8;
        color: #fff; 
        border-radius: 5px; 
    }

    .react-calendar__tile--active.evento {
        background-color: #ffc107; 
        color: #fff; 
        border-radius: 50%; 
    }

    @media screen and (max-width: 1024px) {
     display: none;    
    }
`;