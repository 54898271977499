export default class HomeLacosModel {
    id: number | null;
    titulo: string | null;
    descricao: string | null;
    link_Laco: string | null;
    slide_Laco_Url: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    publishedAt: string | null;

    constructor(
        id: number | null,
        titulo: string | null,
        descricao: string | null,
        link_Laco: string | null,
        slide_Laco_Url: string | null,
        createdAt: string | null,
        updatedAt: string | null,
        publishedAt: string | null,
    ) {
        this.id = id;
        this.titulo = titulo;
        this.descricao = descricao;
        this.link_Laco = link_Laco;
        this.slide_Laco_Url = slide_Laco_Url;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
        this.publishedAt = publishedAt;
    }

    static fromApi(data: any): HomeLacosModel {
        return new HomeLacosModel(
            data.id || null,
            data.attributes.titulo || null,
            data.attributes.descricao || null,
            data.attributes.link_laco || null,
            data.attributes.imagem_laco?.data?.attributes?.url || null, 
            data.attributes.createdAt || null,
            data.attributes.updatedAt || null,
            data.attributes.publishedAt || null
        );
    }
}
