export default class NewsModel {
    id: number;
    titulo: string;
    descricao: string;
    resumo: string;
    tag: string;
    createdAt: string;
    updatedAt: string;
    publishedAt: string;
    imagem_noticia: string;
  
    constructor(
      id: number,
      titulo: string,
      descricao: string,
      resumo: string,
      tag: string,
      createdAt: string,
      updatedAt: string,
      publishedAt: string,
      imagem_noticia: string
    ) {
      this.id = id;
      this.titulo = titulo;
      this.descricao = descricao;
      this.resumo = resumo;
      this.tag = tag;
      this.createdAt = createdAt;
      this.updatedAt = updatedAt;
      this.publishedAt = publishedAt;
      this.imagem_noticia = imagem_noticia;
    }
  }