import styled from "styled-components";
import { IoIosArrowDropdown } from "react-icons/io";

interface CardProps {
  color?: string;
}

export const GalleryStyle = styled.div`
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
`;

export const HorizontalDivision = styled.div`
  width: 100%;
  border-bottom: 1px solid #000000;
  margin-top: 100px;
`;

export const RectangleDivisor = styled.div`
  display: flex;
  width: 400px;
  height: 80px;
  background-color: white;
  position: absolute;
  align-items: center;
  justify-content: center;
  top: 340px;
  left: 50%;
  z-index: 2;
  transform: translate(-50%, -50%);
`;

export const InsideRectangleDivisor = styled.select`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 180px;
  height: 60px;
  background-color: #dae8f9;
  border-radius: 10px;
  appearance: none;
  border: none;
  font-family: "Cinzel", sans-serif;
  font-size: 25pt;
  font-weight: 700;
  text-align: center;
  color: #414c80;
  padding-right: 40px; 
  position: relative;

  option {
    font-family: "Cinzel", sans-serif;
    font-size: 25pt;
    font-weight: 700;
    text-align: center;
    color: #414c80;
  }
`;

export const DropdownIconWrapper = styled.div`
  position: absolute;
  right: 35px;
  z-index: 2;
`;

export const RectangleDivisorText = styled.h1`
  font-family: "Cinzel", sans-serif;
  font-size: 25pt;
  font-weight: 700;
  text-align: center;
  align-self: center;
  margin: 0;
  color: #414c80;
`;

export const WrapContent = styled.div`
  width: 75%;
`;

export const GalleryPageTitle = styled.h1`
  font-family: "Baloo Bhai 2", sans-serif;
  font-size: 35pt;
  font-weight: 500;
  color: #414c80;
  align-self: center;
  text-align: center;
  margin-bottom: 0;
`;

export const GallerySubText = styled.h1`
  font-family: "Actor", sans-serif;
  font-size: 13pt;
  font-weight: 400;
  color: black;
  align-self: center;
  text-align: center;
  margin-top: 0;
`;

export const GallerySubText2 = styled.h1`
  font-family: "Actor", sans-serif;
  font-size: 13pt;
  font-weight: 400;
  color: #1270b0;
  align-self: center;
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
`;

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 220px;
  margin-left: 25px;
  align-self: center;

  @media (max-width: 991px) {
    grid-template-columns: repeat(1, 1fr);
    align-self: center;
  }
`;

export const ColumnMediaTitle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
`;

export const MediaTitle = styled.h1`
  font-family: "Baloo Bhai 2", sans-serif;
  font-size: 16pt;
  font-weight: 500;
  color: #414c80;
  margin-bottom: 0;
  margin-top: 230px;
  margin-right: 15px;
  width: 85%;
  text-align: center;

  @media (max-width: 1500px) {
    font-size: 11pt;
    margin-left: 25px;
  }
`;

export const WrapMedia = styled.div`
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 150px;
  margin-bottom: 150px;

  .gallery {
    width: 95%;
    max-width: 800px;
    display: flex;
    flex-wrap: wrap;
    cursor: pointer;
  }

  .gallery img {
    width: 130px;
    height: 150px;
    border-radius: 8px;
    object-fit: cover;
    position: absolute;
    transform-origin: center;
  }

  .gallery .normal {
    width: 200px;
  }

  .gallery .card {
    width: 130px;
    height: 130px;
    background-color: ${(props: CardProps) => props.color || "#ccc"};
    border-radius: 8px;
    position: absolute;
    margin: 5px;
  }

  .gallery img:not(.normal) {
    opacity: 0.55;
  }

  .upside-down {
    transform: rotate(240deg) translateY(-40%);
  }

  .diagonal {
    transform: rotate(-25deg) translateY(-20%);
  }

  .vertical {
    transform: rotate(130deg) translateY(-60%);
  }

  .rotated {
    transform: rotate(48deg) translateY(-45%) translateX(15%);
  }
`;
