import styled from "styled-components";

export const FooterContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #465763;
    width: 100%;
    height: 550px;
    gap: 60px;

    @media screen and (max-width: 1024px) {
        flex-direction: column;
        height: 700px;
        gap: 10px;
    }
`

export const Sub = styled.div`
    width: 190px;
    border-bottom: 1px solid white; 
    /* margin-bottom: 45px; */
    margin-top: 0;
    padding-top: 0;
    margin-bottom: 8px;

    @media screen and (max-width: 1024px) {
        display: none;
    }
`;

export const FirstColumn = styled.div`
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    margin-right: 50px;
    margin-top: 40px;
    margin-left: 15px;
`

export const FirstColumnTitle = styled.h1`
    font-family: "Baloo Bhai 2", sans-serif;
    font-weight: 600;
    font-size: 10pt;
    color: white;
    margin-bottom: 0;
    padding-bottom: 0;
`

export const WrapTitleSub = styled.div`
    display: flex;
    flex-direction: column;
`

export const ColumnsContent = styled.a`
    font-family: "Baloo Bhai 2", sans-serif;
    font-weight: 500;
    font-size: 10pt;
    color: white;
    margin-bottom: 5px;
    padding-bottom: 0;
    text-decoration: none;
    cursor: pointer;
`

export const VerticalDivisor = styled.div`
    height: 450px;
    border-left: 2px solid #000000; 

    @media screen and (max-width: 1024px) {
        display: none;
    }
`

export const RightColumn = styled.div`
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    margin-top: 40px;
    gap: 15px;
`

export const LogoStyle = styled.img`
    /* height: 180px; */
    width: 300px; 
    /* padding-left: 40px; */

    @media screen and (max-width: 1024px) {
        height: 90px;
        width: 100px ;
        padding-left: 24px;
    }
`
export const WrapIconContent = styled.div`
    display: flex;
    flex-direction: row;
    /* justify-content: center;
    align-items: center; */
    gap: 5px;
    margin-left: 12px;
`
export const ColumnsContent2 = styled.a`
    font-family: "Baloo Bhai 2", sans-serif;
    font-weight: 500;
    font-size: 10pt;
    color: white;
    padding-bottom: 0;
    /* cursor: pointer; */
`
