import BookModel, { FormatDetailModel, FormatsModel, FotoModel } from "../model/bookclub";
import { fetchBooksClub } from "../provider/api";


export async function getBooks(): Promise<{ livros: BookModel[] | null }> {
  try {
      const response = await fetchBooksClub(); 

      const livrosData = response.data;

      const livros = livrosData.map((livroData: any) => {
          const attributes = livroData.attributes;
          const fotoLivroData = attributes.foto_livro?.data;
          
          const fotoLivro = fotoLivroData ? new FotoModel(
              fotoLivroData.id,
              fotoLivroData.attributes.name,
              fotoLivroData.attributes.alternativeText,
              fotoLivroData.attributes.caption,
              fotoLivroData.attributes.width,
              fotoLivroData.attributes.height,
              new FormatsModel(
                  fotoLivroData.attributes.formats?.thumbnail ? new FormatDetailModel(
                      fotoLivroData.attributes.formats.thumbnail.name,
                      fotoLivroData.attributes.formats.thumbnail.hash,
                      fotoLivroData.attributes.formats.thumbnail.ext,
                      fotoLivroData.attributes.formats.thumbnail.mime,
                      fotoLivroData.attributes.formats.thumbnail.path,
                      fotoLivroData.attributes.formats.thumbnail.width,
                      fotoLivroData.attributes.formats.thumbnail.height,
                      fotoLivroData.attributes.formats.thumbnail.size,
                      fotoLivroData.attributes.formats.thumbnail.sizeInBytes,
                      fotoLivroData.attributes.formats.thumbnail.url
                  ) : null,
                  fotoLivroData.attributes.formats?.small ? new FormatDetailModel(
                      fotoLivroData.attributes.formats.small.name,
                      fotoLivroData.attributes.formats.small.hash,
                      fotoLivroData.attributes.formats.small.ext,
                      fotoLivroData.attributes.formats.small.mime,
                      fotoLivroData.attributes.formats.small.path,
                      fotoLivroData.attributes.formats.small.width,
                      fotoLivroData.attributes.formats.small.height,
                      fotoLivroData.attributes.formats.small.size,
                      fotoLivroData.attributes.formats.small.sizeInBytes,
                      fotoLivroData.attributes.formats.small.url
                  ) : null,
                  fotoLivroData.attributes.formats?.medium ? new FormatDetailModel(
                      fotoLivroData.attributes.formats.medium.name,
                      fotoLivroData.attributes.formats.medium.hash,
                      fotoLivroData.attributes.formats.medium.ext,
                      fotoLivroData.attributes.formats.medium.mime,
                      fotoLivroData.attributes.formats.medium.path,
                      fotoLivroData.attributes.formats.medium.width,
                      fotoLivroData.attributes.formats.medium.height,
                      fotoLivroData.attributes.formats.medium.size,
                      fotoLivroData.attributes.formats.medium.sizeInBytes,
                      fotoLivroData.attributes.formats.medium.url
                  ) : null,
                  fotoLivroData.attributes.formats?.large ? new FormatDetailModel(
                      fotoLivroData.attributes.formats.large.name,
                      fotoLivroData.attributes.formats.large.hash,
                      fotoLivroData.attributes.formats.large.ext,
                      fotoLivroData.attributes.formats.large.mime,
                      fotoLivroData.attributes.formats.large.path,
                      fotoLivroData.attributes.formats.large.width,
                      fotoLivroData.attributes.formats.large.height,
                      fotoLivroData.attributes.formats.large.size,
                      fotoLivroData.attributes.formats.large.sizeInBytes,
                      fotoLivroData.attributes.formats.large.url
                  ) : null
              ),
              fotoLivroData.attributes.hash,
              fotoLivroData.attributes.ext,
              fotoLivroData.attributes.mime,
              fotoLivroData.attributes.size,
              fotoLivroData.attributes.url,
              fotoLivroData.attributes.previewUrl,
              fotoLivroData.attributes.provider,
              fotoLivroData.attributes.provider_metadata,
              fotoLivroData.attributes.createdAt,
              fotoLivroData.attributes.updatedAt
          ) : null;

          return new BookModel(
              livroData.id,
              attributes.ano,
              attributes.mes,
              attributes.livro,
              attributes.descricao,
              attributes.titulo,
              attributes.createdAt,
              attributes.updatedAt,
              attributes.publishedAt,
              fotoLivro
          );
      });

      return { livros };

  } catch (error: any) {
      console.error(`Erro (${error.response?.status}) ao buscar livros`);
      console.log(error.toString());
      return { livros: null };
  }
}
