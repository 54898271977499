import TripModel from "../model/trip";
import { fetchTrips } from "../provider/api";

export async function getTrips(date: string): Promise<{ events: TripModel[] }> {
    try {
        const responseData = await fetchTrips(date);

        const items = responseData.data.map((item: any) => {
            const attributes = item.attributes;

            
            const fotoData = attributes.foto?.data?.attributes.url || null;
            

            return new TripModel(
                item.id,
                attributes.data,
                attributes.titulo,
                attributes.descricao,
                attributes.sub_titulo,
                attributes.createdAt,
                attributes.updatedAt,
                attributes.publishedAt,
                fotoData 
            );
        });

        return { events: items };

    } catch (error: any) {
        console.error(`Erro (${error.response?.status}) ao buscar eventos`);
        console.log(error.toString());
        return { events: [] };
    }
}
