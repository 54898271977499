import styled from "styled-components";
import { IoArrowBackCircleOutline } from "react-icons/io5";

export const NewsDetailsPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  

  @media screen and (max-width: 768px) {
    /* padding: 10px; */
  }
`;

export const WrapContent = styled.div`
  width: 72%;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 768px) {
    width: 100%;
    justify-content: center;
    align-items: center;
  }
`;

export const WrapBackButton = styled.button`
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    background-color: transparent;
    border: none;
    gap: 15px;

  @media screen and (max-width: 768px) {
    margin-top: 30px;
  }
`;

export const GoBackButton = styled.h1`
    font-family: "Cinzel", sans-serif;
    font-size: 15pt;
    font-weight: 400;
    align-self: self-start;
    color: black;
    background-color: transparent;
    border: none;
    margin-top: 45px;
    cursor: pointer;

  @media screen and (max-width: 768px) {
    font-size: 12pt;
  }
`;

export const StyledArrowIcon = styled(IoArrowBackCircleOutline)`
  color: #000;
  margin-top: 45px;

  @media screen and (max-width: 768px) {
    margin-top: 30px;
    size: 18px;
  }
`;

export const ImageStart = styled.img`
  height: 240px;
  width: 350px;
  align-self: self-start;
  margin-top: 25px;
  margin-bottom: 20px;
  border-radius: 5px;
  object-fit: cover;

  @media screen and (max-width: 1024px) {
    height: 180px;
    width: 280px;
    display: none;
  }

  @media screen and (max-width: 768px) {
    height: 150px;
    width: 220px;
    padding-left: 0;
  }
`;

interface CategoryTagProps {
  newColor?: string;
}

export const CategoryTag = styled.div<CategoryTagProps>`
  background-color: ${(props) => props.newColor || "#85A261"};
  color: white;
  font-family: "Baloo Bhai 2", sans-serif;
  font-size: 10pt;
  font-weight: 500;
  border-radius: 5px;
  width: 65px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 40px;
  margin-left: 30px;

  @media screen and (max-width: 768px) {
    margin-top: 20px;
    margin-left: 0;
  }
`;

export const RowImageText = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

export const WrapTagAndText = styled.div`
  display: flex;
  flex-direction: column;
  

  img {
    height: 240px;
    width: 350px;
    align-self: self-start;
    margin-top: 25px;
    margin-bottom: 20px;
    margin-left: 20px;
    border-radius: 5px;
    object-fit: cover;

    @media screen and (max-width: 1024px) {
      height: 180px;
      width: 280px;
      /* margin-left: 10px; */
      align-self: center;
    }

    @media screen and (max-width: 768px) {
      height: 150px;
      width: 220px;
      margin-left: 0;
      margin-top: 10px;
    }
  }
`;

export const TitleDetails = styled.h1`
  color: #000000;
  font-family: "Cinzel", sans-serif;
  font-weight: 700;
  font-size: 18pt;
  margin-top: 22px;
  margin-left: 30px;
  margin-bottom: 0;

  @media screen and (max-width: 768px) {
    font-size: 14pt;
    margin-top: 10px;
    text-align: center;
    width: 90%;
  }
`;

export const ContentDetails = styled.h1`
  color: #000000;
  font-family: "Actor", sans-serif;
  font-weight: 400;
  font-size: 10pt;
  margin-top: 0;
  margin-left: 30px;
  margin-bottom: 40px;
  line-height: 2.5;
  text-align: justify;
  align-self: flex-start;
  margin-right: 15px;
  width: 100%;

  @media screen and (max-width: 768px) {
    font-size: 10pt;
    margin-left: 0;
    margin-right: 0;
    margin-top: 10px;
    text-align: center;
    line-height: 1.8;
  }
`;

export const LinkStyle = styled.h1`
  color: #000000;
  font-family: "Actor", sans-serif;
  font-weight: 400;
  font-size: 10pt;
  margin-top: 0;
  margin-left: 30px;
  margin-bottom: 10px;
  line-height: 2.5;
  text-align: justify;
  align-self: flex-start;
  margin-right: 15px;
  width: 100%;

  @media screen and (max-width: 768px) {
    font-size: 9pt;
    margin-left: 0;
    margin-right: 0;
    line-height: 1.8;
  }
`;

export const WrapLinks = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 65px;

  @media screen and (max-width: 768px) {
    margin-bottom: 30px;
  }
`;
