import styled from "styled-components";

export const OutsideColumn = styled.div`
  width: 100%;
  min-height: 1800px;
  display: flex;
  flex-direction: column;
 

  @media screen and (max-width: 768px) {
    /* padding: 20px; */
    padding-top: 50px;
  }
`;

export const HistoryAboutContainer = styled.div`
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 200px;
  position: relative;
  padding: 20px;
  box-sizing: border-box;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
    align-items: center;
    gap: 50px;
  }
`;

export const LeftPartHistory = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-start;

  @media screen and (max-width: 1024px) {
    align-items: center;
  }
`;

export const RightPartHistory = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-start;

  @media screen and (max-width: 1024px) {
    align-items: center;
  }
`;

export const ImageCard = styled.img`
  width: 300px;
  height: auto;
  max-height: 250px;
  background-color: transparent;
  border-radius: 8px;
  object-fit: cover;
  margin-bottom: 70px;
  margin-top: 50px;

  @media screen and (max-width: 768px) {
    width: 100%;
    max-height: 200px;
    margin-top: 20px;
    margin-bottom: 40px;
  }
`;

export const LeftTextStyle = styled.h1`
  font-family: "Baloo Bhai 2", sans-serif;
  font-size: 10pt;
  font-weight: 500;
  text-align: right;
  width: 300px;
  

  @media screen and (max-width: 768px) {
    text-align: center;
    width: 100%;
    font-size: 12pt;
  }
`;

export const RightTextStyle = styled.h1`
  font-family: "Baloo Bhai 2", sans-serif;
  font-size: 10pt;
  font-weight: 500;
  text-align: left;
  width: 300px;

  @media screen and (max-width: 768px) {
    text-align: center;
    width: 100%;
    font-size: 12pt;
  }
`;

export const WrapLeftTextAndTitle = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: flex-end;

  @media screen and (max-width: 1024px) {
    align-items: center;
  }
`;

export const WrapRightTextAndTitle = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: flex-start;

  @media screen and (max-width: 1024px) {
    align-items: center;
  }
`;

export const LeftTitle = styled.h1`
  font-family: "Baloo Bhai 2", sans-serif;
  font-size: 15pt;
  font-weight: 500;
  text-align: right;
  margin: 0;
  position: relative;

  &:before {
    content: "";
    width: 20px;
    height: 20px;
    background-color: #1270b0;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    right: -110px;
    transform: translateY(-50%);

    @media screen and (max-width: 1024px) {
      display: none;
    }
  }

  @media screen and (max-width: 768px) {
    text-align: center;
    font-size: 14pt;
  }
`;

export const RightTitle = styled.h1`
  font-family: "Baloo Bhai 2", sans-serif;
  font-size: 15pt;
  font-weight: 500;
  text-align: left;
  margin: 0;
  position: relative;
  

  &:before {
    content: "";
    width: 20px;
    height: 20px;
    background-color: #1270b0;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: -110px;
    transform: translateY(-50%);

    @media screen and (max-width: 1024px) {
      display: none;
    }
  }

  @media screen and (max-width: 768px) {
    text-align: center;
    font-size: 14pt;
  }
`;

export const VerticalLine = styled.div`
  width: 2px;
  height: 95%;
  background-color: #1270b0;
  position: absolute;
  left: 50%;
  top: 10px;
  transform: translateX(-50%);

  @media screen and (max-width: 1024px) {
    display: none;
  }
`;

export const FinalText = styled.h1`
  font-family: "Baloo Bhai 2", sans-serif;
  font-weight: 400;
  font-size: 14pt;
  text-align: center;
  width: 80%;
  align-self: center;
  margin-bottom: 35px;
  margin-top: 0;

  @media screen and (max-width: 768px) {
    width: 100%;
    font-size: 12pt;
  }
`;

export const AssociatedButton = styled.button`
  background-color: transparent;
  align-self: center;
  margin-top: 30px;
  width: 300px;
  font-size: 14pt;
  height: 40px;
  border-width: 0.5px;
  padding: 0 12px;
  color: #6675c1;
  border-color: #414c80;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-family: "Commissioner", sans-serif;
  font-weight: 500;
  font-style: italic;

  @media screen and (max-width: 1024px) {
    font-size: 12pt;
    width: 90%;
  }
`;
