export default class MemoryModel {
    id: number | null;
    titulo: string | null;
    descricao: string | null;
    sub_titulo: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    publishedAt: string | null;
    fotoSmallUrl: string | null;

    constructor(
        id: number | null,
        titulo: string | null,
        descricao: string | null,
        sub_titulo: string | null,
        createdAt: string | null,
        updatedAt: string | null,
        publishedAt: string | null,
        fotoSmallUrl: string | null
    ) {
        this.id = id;
        this.titulo = titulo;
        this.descricao = descricao;
        this.sub_titulo = sub_titulo;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
        this.publishedAt = publishedAt;
        this.fotoSmallUrl = fotoSmallUrl;
    }

    static fromApi(data: any): MemoryModel {
        const fotoSmallUrl = data.attributes.foto?.data?.attributes?.formats?.small?.url || null;
        
        return new MemoryModel(
            data.id || null,
            data.attributes.titulo || null,
            data.attributes.descricao || null,
            data.attributes.sub_titulo || null,
            data.attributes.createdAt || null,
            data.attributes.updatedAt || null,
            data.attributes.publishedAt || null,
            fotoSmallUrl
        );
    }
}
