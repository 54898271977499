import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  ABOUT,
  ACTIVITIES,
  EVENTS,
  GALLERY,
  LANDING_PAGE,
  NEWS,
} from "../../core/app-urls";
import {
  DesktopHeaderStyle,
  MobileHeaderStyle,
  LogoStyle,
  MenuIcon,
  MobileMenu,
  MobileMenuItem,
  WrapAssociatedButtons,
  AssociatedButtons,
  AssociatedButtons2,
  HeaderItems,
  WrapHeaderItemsLine,
  HorizontalLine,
  LogoWrapper,
  LogoHoverText,
} from "../../styles/Header";
import LOGO from "../../assets/img/abace_logo.png";
import { version } from "../../utils/Constants";

export default function Header() {
  const location = useLocation();
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);

  const isActive = (path: string) => location.pathname === path;

  const handleNavigation = (path: string) => {
    navigate(path);
    setMenuOpen(false);
  };

  return (
    <>
      <DesktopHeaderStyle>
        <LogoWrapper>
          <a href={LANDING_PAGE}>
            <LogoStyle src={LOGO} alt="Logo" />
            <LogoHoverText>{version}</LogoHoverText>
          </a>
        </LogoWrapper>
        <WrapHeaderItemsLine>
          <HeaderItems onClick={() => handleNavigation(LANDING_PAGE)}>
            INÍCIO
          </HeaderItems>
          {isActive(LANDING_PAGE) && <HorizontalLine size={false} />}
        </WrapHeaderItemsLine>
        <WrapHeaderItemsLine>
          <HeaderItems onClick={() => handleNavigation(ABOUT)}>
            A ABACE
          </HeaderItems>
          {isActive(ABOUT) && <HorizontalLine size={false} />}
        </WrapHeaderItemsLine>
        <WrapHeaderItemsLine>
          <HeaderItems onClick={() => handleNavigation(NEWS)}>
            NOTÍCIAS
          </HeaderItems>
          {location.pathname.startsWith(NEWS) && (
            <HorizontalLine size={false} />
          )}
        </WrapHeaderItemsLine>
        <WrapHeaderItemsLine>
          <HeaderItems onClick={() => handleNavigation(EVENTS)}>
            EVENTOS E ATIVIDADES
          </HeaderItems>
          {isActive(EVENTS) && <HorizontalLine size={true} />}
        </WrapHeaderItemsLine>
        <WrapHeaderItemsLine>
          <HeaderItems onClick={() => handleNavigation(ACTIVITIES)}>
            PARCERIAS E CONVÊNIOS
          </HeaderItems>
          {location.pathname.startsWith(ACTIVITIES) && (
            <HorizontalLine size={true} />
          )}
        </WrapHeaderItemsLine>
        <WrapHeaderItemsLine>
          <HeaderItems onClick={() => handleNavigation(GALLERY)}>
            GALERIA
          </HeaderItems>
          {isActive(GALLERY) && <HorizontalLine size={false} />}
        </WrapHeaderItemsLine>
        <WrapAssociatedButtons>
          <AssociatedButtons>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="http://189.90.138.250:2020/portalabace/#/"
            >
              ÁREA DO ASSOCIADO
            </a>
          </AssociatedButtons>
          <AssociatedButtons2>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://forms.gle/ySkfypXFVAqCguCY6"
            >
              OU ASSOCIE-SE AQUI
            </a>
          </AssociatedButtons2>
        </WrapAssociatedButtons>
      </DesktopHeaderStyle>

      <MobileHeaderStyle>
        <a href={LANDING_PAGE}>
          <LogoStyle src={LOGO} alt="Logo" />
        </a>
        <MenuIcon onClick={() => setMenuOpen(!menuOpen)}>☰</MenuIcon>
        {menuOpen && (
          <MobileMenu>
            <MobileMenuItem onClick={() => handleNavigation(LANDING_PAGE)}>
              INÍCIO
            </MobileMenuItem>
            <MobileMenuItem onClick={() => handleNavigation(ABOUT)}>
              A ABACE
            </MobileMenuItem>
            <MobileMenuItem onClick={() => handleNavigation(NEWS)}>
              NOTÍCIAS
            </MobileMenuItem>
            <MobileMenuItem onClick={() => handleNavigation(EVENTS)}>
              EVENTOS E ATIVIDADES
            </MobileMenuItem>
            <MobileMenuItem onClick={() => handleNavigation(ACTIVITIES)}>
              PARCERIAS E CONVÊNIOS
            </MobileMenuItem>
            <MobileMenuItem onClick={() => handleNavigation(GALLERY)}>
              GALERIA
            </MobileMenuItem>
            <WrapAssociatedButtons>
              <AssociatedButtons>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="http://189.90.138.250:2020/portalabace/#/"
                >
                  ÁREA DO ASSOCIADO
                </a>
              </AssociatedButtons>
              <AssociatedButtons2>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://forms.gle/ySkfypXFVAqCguCY6"
                >
                  OU ASSOCIE-SE AQUI
                </a>
              </AssociatedButtons2>
            </WrapAssociatedButtons>
          </MobileMenu>
        )}
      </MobileHeaderStyle>
    </>
  );
}
