import styled, { css } from "styled-components";
import { IoArrowBackCircleOutline } from "react-icons/io5";

export const GalleryItemsPageStyle = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
`

export const WrapContent = styled.div`
    display: flex;
    align-self: center;
    width: 80%;
`

export const WrapBackButton = styled.button`
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    background-color: transparent;
    border: none;
    gap: 15px
`

export const GoBackButton = styled.h1`
    font-family: "Cinzel", sans-serif;
    font-size: 15pt;
    font-weight: 400;
    align-self: self-start;
    color: black;
    background-color: transparent;
    border: none;
    margin-top: 55px;
    cursor: pointer;
`

export const StyledArrowIcon = styled(IoArrowBackCircleOutline)`
  color: #000; 
  margin-top: 45px;
`;

export const GalleryItemsTitle = styled.h1`
    font-family: "Baloo Bhai 2", sans-serif;
    font-size: 35pt;
    font-weight: 500;
    color: #414c80;
    align-self: center;
    text-align: center;
    margin-bottom: 0;
    margin-top: 50px;
`

export const GalleryItemsPageSub = styled.h1`
    font-family: "Actor", sans-serif;
    font-size: 10pt;
    font-weight: 400;
    color: black;
    align-self: center;
    text-align: center;
    margin-bottom: 0;
    margin-top: 15px;
`

export const GalleryItemsFilterRow = styled.div`
    display: flex;
    flex-direction: row;
    gap: 15px;
    width: 100%;
    align-self: center;
    justify-content: center;
    margin-top: 55px;
`

export const GalleryItemsFilterButtons = styled.button`
    background-color: white;
    border-color: #FF8787;
    color: #FF8787;
    border-radius: 7px;
    border-width: 1px;
    width: 100px;
    font-family: "Baloo Bhai 2", sans-serif;
    font-size: 15pt;
    font-weight: 400;
    border-style: solid;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    cursor: pointer;
`

export const GalleryItemsSlider = styled.div`
    position: relative;
    width: 100%;
    height: 310px;
    /* background-color: #E7F2F6; */
    margin-bottom: 90px;
    margin-top: 70px;
    display: flex;
    overflow-x: hidden;
    overflow-y: hidden;
    scroll-behavior: smooth;
    align-items: center;
    gap: 20px;
`

export const ImageSlider = styled.img`
  height: auto;
  max-height: 260px;
  width: auto;
  object-fit: cover;
  padding: 15px;
  opacity: .5;
  
  &.focused {
    opacity: 1;
    transform: scale(1.2); 
    z-index: 1;
  }
`;

export const ButtonIconLeft = styled.button`
    position: absolute;
    left: 0;
    top: 480px;
    transform: translateY(-50%);
    z-index: 999;
    background-color: transparent;
    border: none;
    cursor: pointer;
`;

export const ButtonIconRight = styled.button`
    position: absolute;
    right: 0;
    top: 480px;
    transform: translateY(-50%);
    z-index: 999;
    background-color: transparent;
    border: none;
    cursor: pointer;
`;

export const RightArrow = styled.img`
`

export const LeftArrow = styled.img`
    transform: rotate(180deg);
`

export const GalleryItemsStyle = styled.div`
    -webkit-column-count: 3;
    column-count: 3;
    -moz-column-count: 3;
    -webkit-column-width: 33%;
    -moz-column-width: 33%;
    column-width: 33%;
    padding: 0 12px;
    margin-top: 100px;

    @media (max-width: 991px){
        -webkit-column-count: 2;
        column-count: 2;
        -moz-column-count: 2;
    }

    @media (max-width: 480px){
        -webkit-column-count: 1;
        column-count: 1;
        -moz-column-count: 1;
    }

`


export const Pics = styled.div`
    -webkit-transition: all 350ms ease;
    transition: all 350ms ease;
    cursor: pointer;
    margin-bottom: 12px;

    &:hover{
        filter: opacity(.6);
    }
`

interface ModelProps {
    open?: string;
}

export const Model = styled.div<ModelProps>`
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black;
    visibility: hidden;
    opacity: 0;
    transform: scale(0);
    overflow: hidden;
    z-index: 999;
    transition: opacity .4s ease, visibility .4s ease, transform .5s ease-in-out;

    @media screen and (max-width: 1024px) {
        transition: none;
  }

    ${(props) =>
    props.open &&
    css`
      visibility: visible;
      opacity: 1;
      transform: scale(1);    

      @media screen and (max-width: 1024px) {
        display: none;
        transform: scale(0);
        opacity: none;
        }
    `}

    .img{
        width: auto;
        max-width: 100%;
        height: auto;
        max-height: 100%;
        display: block;
        line-height: 0;
        box-sizing: border-box;
        padding: 20px 0 20px;
        margin: 0 auto;
    }
`

export const CloseIcon = styled.div`
        position: fixed;
        top: 10px;
        right: 10px;
        text-align: left;
        width: 100px;
        height: auto;
        padding: 5px;
        background-color: rgba(0,0,0,0.4);
        color: white;
        cursor: pointer
`



