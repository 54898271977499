import EventModel from "../model/events";
import { fetchEvents } from "../provider/api";


export interface Evento {
  titulo: string | null;
  descricao: string | null;
  data: string | null;
}

export interface EventosPorData {
  [data: string]: Evento[];
}


function agruparEventosPorData(eventos: EventModel[]): EventosPorData {
  return eventos.reduce((acc: EventosPorData, evento: EventModel) => {
    const dataEvento = evento.data_evento;

    if (!acc[dataEvento!]) {
      acc[dataEvento!] = [];
    }

    acc[dataEvento!].push({
      titulo: evento.nome_evento,
      descricao: evento.descricao_evento,
      data: evento.data_evento
    });

    return acc;
  }, {});
}

export async function getEventosDinamicos(): Promise<EventosPorData> {
  try {

    const responseData = await fetchEvents(); 

    
    const eventos = responseData.data.map((item: any) => {
      const attributes = item.attributes;

      return new EventModel(
        item.id,
        attributes.nome_evento,
        attributes.descricao_evento,
        attributes.data_evento,
      );
    });

    
    const eventosPorData = agruparEventosPorData(eventos);
    return eventosPorData;

  } catch (error: any) {
    console.error(`Erro (${error.response?.status}) ao buscar eventos`);
    return {}; 
  }
}




