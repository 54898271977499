export default class BookModel {
    id: number | null;
    ano: string | null;
    mes: string | null;
    livro: string | null;
    descricao: string | null;
    titulo: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    publishedAt: string | null;
    foto_livro: FotoModel | null;

    constructor(
        id: number | null,
        ano: string | null,
        mes: string | null,
        livro: string | null,
        descricao: string | null,
        titulo: string | null,
        createdAt: string | null,
        updatedAt: string | null,
        publishedAt: string | null,
        foto_livro: FotoModel | null
    ) {
        this.id = id;
        this.ano = ano;
        this.mes = mes;
        this.livro = livro;
        this.descricao = descricao;
        this.titulo = titulo;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
        this.publishedAt = publishedAt;
        this.foto_livro = foto_livro;
    }
}

export class FotoModel {
    id: number | null;
    name: string | null;
    alternativeText: string | null;
    caption: string | null;
    width: number | null;
    height: number | null;
    formats: FormatsModel | null;
    hash: string | null;
    ext: string | null;
    mime: string | null;
    size: number | null;
    url: string | null;
    previewUrl: string | null;
    provider: string | null;
    provider_metadata: string | null;
    createdAt: string | null;
    updatedAt: string | null;

    constructor(
        id: number | null,
        name: string | null,
        alternativeText: string | null,
        caption: string | null,
        width: number | null,
        height: number | null,
        formats: FormatsModel | null,
        hash: string | null,
        ext: string | null,
        mime: string | null,
        size: number | null,
        url: string | null,
        previewUrl: string | null,
        provider: string | null,
        provider_metadata: string | null,
        createdAt: string | null,
        updatedAt: string | null
    ) {
        this.id = id;
        this.name = name;
        this.alternativeText = alternativeText;
        this.caption = caption;
        this.width = width;
        this.height = height;
        this.formats = formats;
        this.hash = hash;
        this.ext = ext;
        this.mime = mime;
        this.size = size;
        this.url = url;
        this.previewUrl = previewUrl;
        this.provider = provider;
        this.provider_metadata = provider_metadata;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
    }
}

export class FormatsModel {
    thumbnail: FormatDetailModel | null;
    small: FormatDetailModel | null;
    medium: FormatDetailModel | null;
    large: FormatDetailModel | null;

    constructor(
        thumbnail: FormatDetailModel | null,
        small: FormatDetailModel | null,
        medium: FormatDetailModel | null,
        large: FormatDetailModel | null
    ) {
        this.thumbnail = thumbnail;
        this.small = small;
        this.medium = medium;
        this.large = large;
    }
}

export class FormatDetailModel {
    name: string | null;
    hash: string | null;
    ext: string | null;
    mime: string | null;
    path: string | null;
    width: number | null;
    height: number | null;
    size: number | null;
    sizeInBytes: number | null;
    url: string | null;

    constructor(
        name: string | null,
        hash: string | null,
        ext: string | null,
        mime: string | null,
        path: string | null,
        width: number | null,
        height: number | null,
        size: number | null,
        sizeInBytes: number | null,
        url: string | null
    ) {
        this.name = name;
        this.hash = hash;
        this.ext = ext;
        this.mime = mime;
        this.path = path;
        this.width = width;
        this.height = height;
        this.size = size;
        this.sizeInBytes = sizeInBytes;
        this.url = url;
    }
}
