import {
  AssociatedButton,
  FinalText,
  HistoryAboutContainer,
  ImageCard,
  LeftPartHistory,
  LeftTextStyle,
  LeftTitle,
  OutsideColumn,
  RightPartHistory,
  RightTextStyle,
  RightTitle,
  VerticalLine,
  WrapLeftTextAndTitle,
  WrapRightTextAndTitle,
} from "../../styles/HistoryAbout";
import ABACEABOUT from "../../assets/img/abaceAbout.png";
import FIRSTPHOTO from "../../assets/img/1987.png";
import SECONDPHOTO from "../../assets/img/1999.png";
import THIRDPHOTO from "../../assets/img/2018.png";
import LASTPHOTO from "../../assets/img/2024.jpg";

export default function HistoryAbout() {
  return (
    <>
      <OutsideColumn>
        <HistoryAboutContainer>
          <VerticalLine />
          <LeftPartHistory>
            <ImageCard src={FIRSTPHOTO}/>
            <WrapLeftTextAndTitle>
              <LeftTitle>Anos de 1999 a 2018</LeftTitle>
              <LeftTextStyle>
                A Associação continuou a crescer e ampliar seu quadro de
                associados, impulsionada não só pelas intensas atividades
                sociais, mas também por ter abraçado, na condição de substituta
                processual, até então permitida, as reivindicações de
                aposentados junto ao governo federal, contratando escritório de
                advocacia para essa finalidade. Foi necessária a mudança para um
                local mais amplo, alugando-se espaços adequados no Shopping
                Venâncio 2000, durante a profícua gestão do presidente Walter
                Gomes de Oliveira.
              </LeftTextStyle>
            </WrapLeftTextAndTitle>
            <ImageCard src={THIRDPHOTO}/>
            <WrapLeftTextAndTitle>
              <LeftTitle>Anos de 2022 até o presente</LeftTitle>
              <LeftTextStyle>
                Na busca da diversidade de suas atividades e o retorno dos
                encontros presencias pós pandemia, a gestão de Humberto
                Cavalcante Lacerda levou a associação para Asbac. Local mais
                apropriado para uma sede social, recreativa e festiva já que a
                antiga sede própria não se adequava, evidentemente, para outro
                tipo de encontro que não fosse para trabalho.
              </LeftTextStyle>
            </WrapLeftTextAndTitle>
          </LeftPartHistory>
          <RightPartHistory>
            <WrapRightTextAndTitle>
              <RightTitle>Junho de 1987</RightTitle>
              <RightTextStyle>
                Começamos a funcionar em 1987, ainda no escritório particular de
                seu primeiro presidente, Carlos Everaldo Álvares Coimbra. vindo
                a ocupar depois outras salas em diferentes andares do Edifício
                do Banco Central. Passado algum tempo, já consolidada e com seus
                dirigentes experimentando o sucesso da empreitada, foi adquirida
                uma pequena sala no prédio da Clínica Pasteur em 1989, onde
                funcionou até o ano de 1999.
              </RightTextStyle>
            </WrapRightTextAndTitle>
            <ImageCard src={SECONDPHOTO}/>
            <WrapRightTextAndTitle>
              <div style={{padding: '40px'}}></div>
              <RightTitle>Anos de 2018 a 2022</RightTitle>
              <RightTextStyle>
                A ideia de aquisição de uma sede administrativa própria foi
                reativada pela administração do presidente Cairo Túlio, em
                dezembro de 2017. Devido ao alto custo com aluguel,
                constituiu-se reserva bancária para tal finalidade, com
                aprovação do Conselho de Administração. O projeto foi abraçado
                pela administração do presidente Elias Bueno, que assinou, dia 2
                de março de 2018, escritura de compra de imóvel no Edifício
                Record, a fim de abrigar a Abace.
              </RightTextStyle>
            </WrapRightTextAndTitle>
            <ImageCard src={LASTPHOTO}/>
            <RightTitle>Escrevendo histórias...</RightTitle>
          </RightPartHistory>
        </HistoryAboutContainer>
        <div style={{padding: '60px'}}></div>
        <FinalText>
          A união faz a força. A ABACE – Associação Brasiliense de Aposentados
          do Banco Central vem comprovando isso desde sua fundação, em 1987.
          Gozando do mais elevado conceito, a ABACE é uma instituição sólida,
          moderna, ativa, dinâmica, que apoia seus associados na busca dos seus
          interesses e na preservação dos direitos. É considerada instituição de
          referência no ramo de associação de classes.
        </FinalText>
        <FinalText>
          A ABACE está sempre de braços abertos para acolher os colegas
          aposentados, ativos e pensionistas da comunidade do Banco Central.
          Instalou, para isso uma confortável e funcional sede, um sistema de
          comunicação dos mais atualizados pelos quais se mantém em permanente
          contato com seu corpo social, prestando-lhe valiosas e importantes
          informações, pelo site – www.abace.org.br,  pelos canais de mídias
          sociais e emails – abace@abace.org.br  pelos meios tradicionais de
          correspondência via correios e pelo telefone (61) 3323-1390.
        </FinalText>
        <AssociatedButton>SAIBA MAIS</AssociatedButton>
      </OutsideColumn>
      <div style={{padding: '60px'}}></div>
    </>
  );
}
