import styled from "styled-components";

export const EventsPageContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
`

export const WrapContent = styled.div`
    width: 75%;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 1024px) {
        
    }
`

export const EventsPageTitle = styled.h1`
    font-family: "Baloo Bhai 2", sans-serif;
    font-size: 35pt;
    font-weight: 500;
    color: #414C80;
    align-self: flex-start;
    margin-bottom: 0;
`

export const EventsPageSub = styled.h1`
    font-family: "Actor", sans-serif;
    font-size: 15pt;
    font-weight: 400;
    color: black;
    align-self: flex-start;
    margin-top: 0;
`

export const EventsPageSlider = styled.div`
  position: relative;
  width: 100%;
  height: 340px;
  background-color: #E7F2F6;
  margin-top: 10px;
  display: flex;
  overflow-x: hidden;
  scroll-behavior: smooth;
  align-items: center;
  gap: 20px;

  @media screen and (max-width: 1024px) {
    flex-direction: row; 
    gap: 0; 
  }
`;

export const EventsPageSliderCard = styled.div`
  position: relative;
  height: 280px;
  flex: none;
  background-color: white;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 220px;
  margin-top: 0;
  margin-left: 30px;
  margin-right: 10px;
  border-radius: 8px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  cursor: pointer;

  @media screen and (max-width: 1024px) {
    width: 100%; 
    margin: 0;
    padding: 0;
  }
`;

export const ButtonIconLeft = styled.button`
  position: absolute;
  left: 0;
  top: 370px;
  transform: translateY(-50%);
  z-index: 1;
  background-color: transparent;
  border: none;
  cursor: pointer;

  @media screen and (max-width: 1024px) {
    top: 500px;
  }
`;

export const ButtonIconRight = styled.button`
  position: absolute;
  right: 0;
  top: 370px;
  transform: translateY(-50%);
  z-index: 1;
  background-color: transparent;
  border: none;
  cursor: pointer;

  @media screen and (max-width: 1024px) {
    top: 500px;
  }
`;


export const RightArrow = styled.img`

`

export const LeftArrow = styled.img`
    transform: rotate(180deg);
`

export const ImageSliderCard = styled.img`
    height: 60%;
    width: 95%; 
    border-radius: 8px;
    object-fit: cover;
    align-self: center;
    margin-top: 4px;

    @media screen and (max-width: 1024px) {
        height: 60%;
        width: 90%;;
    }
`

export const SliderCardTitle = styled.h1`
    font-family: "Baloo Bhai 2", sans-serif;
    font-size: 15pt;
    font-weight: 500;
    color: #414C80;
    margin-bottom: 0;
    margin-top: 0;
`

export const SliderCardSubtitle = styled.h1`
    font-family: "Actor", sans-serif;
    font-size: 10pt;
    font-weight: 400;
    width: 85%;
    color: #414C80;
    margin-top: 0;
    text-align: center;
    /* padding-left: 200px; */
`

export const NextEventsTitle = styled.h1`
    font-family: "Baloo Bhai 2", sans-serif;
    font-size: 25pt;
    font-weight: 500;
    color: #414C80;
    align-self: flex-start;
    margin-bottom: 0;
    margin-top: 70px;
`

export const NextEventsContainer = styled.div`
    display: flex;
    flex-direction: row;
    background-color: white;
    justify-content: space-between;
    border-color: black;
    border-radius: 8px;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.5);
    width: 100%;
    height: auto;
    margin-bottom: 25px;

    @media screen and (max-width: 1024px) {
        justify-content: center;
        align-items: center;
    }
`

export const NextEventsCardTitle = styled.h1`
    font-family: "Baloo Bhai 2", sans-serif;
    font-size: 22pt;
    font-weight: 500;
    text-align: center;
    width: 200px;
    color: #414C80;
    align-self: center;
    margin-bottom: 0;
    margin-top: 0;

    @media screen and (max-width: 1024px) {
        /* width: 100px; */
    }
`

export const NextEventsCardNumber = styled.h1`
    font-family: "Baloo Bhai 2", sans-serif;
    font-size: 40pt;
    font-weight: 500;
    color: #414C80;
    align-self: center;
    margin: 0;
    padding: 0;
`

export const NextEventFirstColumn = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: 0;
    justify-content: center;
    align-items: center;
    padding-left: 80px;
    
    @media screen and (max-width: 1024px) {
        padding-left: 0;
    }
`

export const NextEventSecondColumn = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: 0;
    /* justify-content: center;
    align-items: center;
    padding-left: 80px; */
`

export const NextEventContent = styled.h1`
    font-family: "Actor", sans-serif;
    font-size: 14pt;
    font-weight: 400;
    width: 300px;
    /* padding-left: 200px; */
    padding-right: 200px;

    @media screen and (max-width: 1024px) {
        display: none;
    }
`

export const KnowMoreButton = styled.button`
    background-color: #1270B0A1;
    color: white;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    border: none;
    cursor: pointer;
    width: 200px;
    height: 30px;
    margin-left: 200px;
    margin-top: 18px;
`

export const CalendarContainer = styled.div`
    display: flex;
    flex-direction: row;
    background-color: #EAEDEE;
    margin-top: 25px;
    margin-bottom: 100px;
    width: 100%;
    height: 400px;
    justify-content: center;

    @media screen and (max-width: 1024px) {
        display: none;
    }
`

export const WrapCalendar = styled.div`
    display: flex;
    flex-direction: column;
    width: 85%;
`

export const CalendarTitle = styled.h1`
    font-family: "Cinzel", sans-serif;
    font-weight: 700;
    font-size: 18pt;
    color: black;
`