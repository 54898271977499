export default class EventModel {
    id: number | null;
    nome_evento: string | null;
    descricao_evento: string | null;
    data_evento: string | null;

    constructor(
        id: number | null,
        nome_evento: string | null,
        descricao_evento: string | null,
        data_evento: string | null,
    ) {
        this.id = id;
        this.nome_evento = nome_evento;
        this.descricao_evento = descricao_evento;
        this.data_evento = data_evento;
    }
}
