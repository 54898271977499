import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import {
  NewsDetailsPageContainer,
  WrapContent,
  WrapBackButton,
  StyledArrowIcon,
  GoBackButton,
  RowImageText,
  ImageStart,
  WrapTagAndText,
  TitleDetails,
  ContentDetails,
  CategoryTag
} from "../../styles/NewsDetailsPage"; 
import { getNews } from "../../data/services/news";
import { NEWS } from "../../core/app-urls";
import { endpoint } from "../../utils/Constants";

const NewsDetailsPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [newsItem, setNewsItem] = useState<any>(null);
  const [imgSrc, setImgSrc] = useState<string>("");
  const fallbackImageUrl = "URL_DA_IMAGEM_DE_FALLBACK";

  useEffect(() => {
    const fetchNewsDetails = async () => {
      const response = await getNews();
      const newsDetails = response.news.find(
        (item: any) => item.id === parseInt(id!)
      );

      if (newsDetails) {
        setNewsItem(newsDetails);
        setImgSrc(`${endpoint}${newsDetails.imagem_noticia}`);
      }
    };

    if (id) {
      fetchNewsDetails();
    }
  }, [id]);

  const handleBackButton = () => {
    navigate(NEWS);
  };

  return (
    <>
      <Header />
      <NewsDetailsPageContainer>
        <WrapContent>
          <WrapBackButton onClick={handleBackButton}>
            <StyledArrowIcon size={23} />
            <GoBackButton>Voltar para NOTÍCIAS</GoBackButton>
          </WrapBackButton>

          {newsItem ? (
            <RowImageText>
              <ImageStart src={imgSrc || fallbackImageUrl} alt={`Imagem de ${newsItem.titulo}`} />
              <WrapTagAndText>
                <CategoryTag newColor={getTagColor(newsItem.tag)}>{newsItem.tag}</CategoryTag>
                <TitleDetails>{newsItem.titulo}</TitleDetails>
                <ContentDetails dangerouslySetInnerHTML={{ __html: newsItem.descricao }} />
              </WrapTagAndText>
            </RowImageText>
          ) : (
            <TitleDetails>Notícia não encontrada</TitleDetails>
          )}
        </WrapContent>
      </NewsDetailsPageContainer>
      <Footer />
    </>
  );
};

function getTagColor(selectedCategory: string): string {
  switch (selectedCategory) {
    case "parcerias":
      return "#1270B0";
    case "eventos":
      return "#85A261";
    case "BC Saúde":
      return "pink";
    case "Reuniões":
      return "gray";
    default:
      return "";
  }
}

export default NewsDetailsPage;
