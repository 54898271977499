import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import PASSEIOS from "../../assets/img/passeios.jpg";
import {
  ClickHereStyle,
  GoBackButton,
  ImageRelationshipCard,
  RelationshipCircularIcon,
  RelationshipDetailsCard,
  RelationshipDetailsCardContent,
  RelationshipDetailsPageCardTitle,
  RelationshipDetailsPageStyle,
  RelationshipDetailsPageTitle,
  RelationshipDetailsSubText2,
  SecondColumnDetailsPage,
  StyledArrowIcon,
  WrapBackButton,
  WrapContent,
  WrapContent2,
} from "../../styles/Relationship";
import { PiAirplaneTiltDuotone } from "react-icons/pi";
import TripModel from "../../data/model/trip";
import { getTrips } from "../../data/services/trips_service";
import { endpoint } from "../../utils/Constants";


export default function Trips() {
  const [expandedCardId, setExpandedCardId] = useState<number | null>(null); 
  const [trips, setTrips] = useState<TripModel[]>([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const handleCardClick = (id: number) => {
    setExpandedCardId(expandedCardId === id ? null : id);
  };

  const handleBackButton = () => {
    navigate("/");
  };

  useEffect(() => {
    async function fetchTripsData() {
      try {
        const today = new Date().toISOString().slice(0, 10);
        console.log(today, "Data");
        const { events } = await getTrips(today.toString());
        setTrips(events);
      } catch (error) {
        console.error("Erro ao buscar as viagens:", error);
      } finally {
        setLoading(false);
      }
    }
    fetchTripsData();
  }, []);

  return (
    <>
      <RelationshipDetailsPageStyle>
        <WrapContent>
          <WrapBackButton onClick={handleBackButton}>
            <StyledArrowIcon size={23} />
            <GoBackButton>Voltar para página inicial</GoBackButton>
          </WrapBackButton>
        </WrapContent>
        <WrapContent2>
          <RelationshipCircularIcon>
            <PiAirplaneTiltDuotone size={50} color="#1270B0" />
          </RelationshipCircularIcon>
          <RelationshipDetailsPageTitle>
            Viagens e passeios
          </RelationshipDetailsPageTitle>
          {loading ? (
            <p>Carregando...</p>
          ) : (
            trips.map((trip) => (
              <RelationshipDetailsCard
                key={trip.id}
                onClick={() => handleCardClick(trip.id!)}
                expanded={expandedCardId === trip.id}
              >
                <ImageRelationshipCard
                  src={`${endpoint}${trip.fotoSmallUrl}`}
                  expanded={expandedCardId === trip.id}
                />
                <SecondColumnDetailsPage>
                  <RelationshipDetailsPageCardTitle
                    expanded={expandedCardId === trip.id}
                  >
                    {trip.titulo}
                  </RelationshipDetailsPageCardTitle>

                  
                  {!expandedCardId || expandedCardId !== trip.id ? (
                    <p>{trip.sub_titulo}</p>
                  ) : null}

                  
                  {expandedCardId === trip.id && (
                    <RelationshipDetailsSubText2
                      dangerouslySetInnerHTML={{ __html: trip.descricao! }}
                    />
                  )}

                  <ClickHereStyle expanded={expandedCardId === trip.id}>
                    Clique e veja todos os detalhes
                  </ClickHereStyle>
                  <RelationshipDetailsCardContent
                    expanded={expandedCardId === trip.id}
                  >
                    <p>Data: {new Date(trip.data + 'T00:00:00').toLocaleDateString()}</p>
                  </RelationshipDetailsCardContent>
                </SecondColumnDetailsPage>
              </RelationshipDetailsCard>
            ))
          )}
        </WrapContent2>
      </RelationshipDetailsPageStyle>
    </>
  );
}
