import styled from "styled-components";

export const BookClubPageTitle = styled.h1`
  font-family: "Baloo Bhai 2", sans-serif;
  font-size: 35pt;
  font-weight: 500;
  color: #414c80;
  align-self: center;
  text-align: center;
  margin-bottom: 0;
  margin-top: 15px;
`;

export const BookClubSubText = styled.h1`
  font-family: "Actor", sans-serif;
  font-size: 13pt;
  font-weight: 400;
  color: #1270b0;
  align-self: center;
  text-align: center;
  margin-top: 0;
  margin-bottom: 15px;
`;

export const BookClubFilterText = styled.h1`
    font-family: "Actor", sans-serif;
    font-size: 12pt;
    font-weight: 400;
    color: black;
    align-self: flex-start;
    margin-top: 20px;
    margin-bottom: 0;
    
    @media screen and (max-width: 1024px) {
        width: 70%;
        align-self: center;
    }
`

export const BookClubRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
  width: 50%;
  align-self: flex-start;
  margin-top: 15px;
  margin-bottom: 15px;

  @media screen and (max-width: 768px) {
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
  }
`;

interface FilterButtonsProps {
    newColor?: string;
    isSelected?: boolean; 
  }
  
  export const FilterButtons = styled.button<FilterButtonsProps>`
    background-color: ${(props) => (props.isSelected ? "#FF8787" : "white")};
    border-color: ${(props) => props.newColor || '#FF8787'};
    color: ${(props) => (props.isSelected ? "white" : props.newColor || '#FF8787')};
    border-radius: 7px;
    border-width: 1px;
    width: 100px;
    font-family: "Baloo Bhai 2", sans-serif;
    font-size: 15pt;
    font-weight: 400;
    border-style: solid;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    cursor: pointer;
    
    &:hover {
      background-color: ${(props) => (props.isSelected ? "#FF8787" : "#f0f0f0")};
    }
  `;

export const WrapBookImageAndText = styled.div`
    display: flex;
    flex-direction: row;
    gap: 50px;

    img{
        width: 400px;
        max-height: 600px;
        object-fit: cover;
    }
`

export const RowDescription = styled.div`
    display: flex;
    width: 50%;
    height: auto;
    flex-direction: column;
    margin-bottom: 500px;

    h2{
        width: 100%;
        max-width: 100%; 
        word-wrap: break-word; 
        overflow-wrap: break-word; 
        white-space: normal;
    }
`